import { Menu, Spin, Typography } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import * as React from 'react';
import { FormattedDate } from 'react-intl';
import styled from 'styled-components';
import { TranslatedMessage } from '../../translations/data';
import { useAppointments } from './queries';
import { FaSyringeIcon } from '../../shared/constants';

const Selection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 200px;
  margin: auto;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 600px;
  margin: auto;
`;

const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ItemLabel = styled.div`
  font-weight: bold;
`;

const DescriptionRow = ({
  label,
  content,
}: {
  label: React.ReactNode;
  content: React.ReactNode;
}) => (
  <ItemRow>
    <ItemLabel>{label}</ItemLabel>
    <div>{content}</div>
  </ItemRow>
);

const DoseDetails = () => {
  const appointmentsQuery = useAppointments();
  const [selectedDose, setSelectedDose] = React.useState(
    (appointmentsQuery.data?.count ?? 1) - 1
  );
  const appointment = appointmentsQuery.data?.appointments[selectedDose];

  React.useEffect(() => {
    if (appointmentsQuery.isSuccess) {
      setSelectedDose(appointmentsQuery.data.count - 1);
    }
  }, [appointmentsQuery.isSuccess]);

  return (
    <>
    <Spin spinning={appointmentsQuery.isLoading}>
    <Selection>
      <Typography.Title style={{ textAlign: 'center' }} level={5}>
        <FaSyringeIcon style={{ color: '#d80000' }} />{' '}
        <TranslatedMessage id="selectDose" />
      </Typography.Title>
      <Menu
        selectedKeys={[`${selectedDose}`]}
        onClick={(e) =>
          setSelectedDose(
            typeof e.key === 'number' ? e.key : parseInt(e.key, 10)
          )
        }
      >
        {appointmentsQuery.data?.appointments.map((_, i) => (
          <Menu.Item key={i}>
            <TranslatedMessage id="numberedDose" values={{ n: i + 1 }} />
          </Menu.Item>
        ))}
      </Menu>
    </Selection>
  </Spin>
  <Spin spinning={appointmentsQuery.isLoading}>
    <Items>
      <Typography.Title style={{ textAlign: 'center' }} level={5}>
        <InfoCircleFilled style={{ color: '#d80000' }} />{' '}
        <TranslatedMessage id="vaccinInfo" />
      </Typography.Title>
      <DescriptionRow
        label={<TranslatedMessage id="NumVaccin" />}
        content={appointment?.vaccin.numVaccin}
      />
      <DescriptionRow
        label={<TranslatedMessage id="NomVaccin" />}
        content={appointment?.vaccin.nomVaccin}
      />
      <DescriptionRow
        label={<TranslatedMessage id="Fournisseur" />}
        content={appointment?.vaccin.fournisseur}
      />
      <DescriptionRow
        label={<TranslatedMessage id="Notice" />}
        content={appointment?.vaccin.notice}
      />
      <DescriptionRow
        label={<TranslatedMessage id="Vaccinateur" />}
        content={appointment?.vaccin.vaccinateur}
      />
      <DescriptionRow
        label={<TranslatedMessage id="vaccinDate" />}
        content={
          <FormattedDate
            value={appointment?.date}
            year="numeric"
            month="long"
            day="2-digit"
            hour="numeric"
            minute="numeric"
          />
        }
      />
    </Items>
  </Spin>
      </>
  );
};

export default DoseDetails;
