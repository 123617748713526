import { Select } from 'antd';
import styled from 'styled-components';
import { TranslatedMessage } from '../../translations/data';

type Category = 'PersonalData' | 'Vaccination' | 'Pass';

type Props = {
  value?: Category;
  onChange?: (_?: Category) => void;
};

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  & > .ant-select {
    width: 80%;
  }
  padding: 16px;
`;

const SelectReportCategory = ({ value, onChange }: Props) => (
  <Centered>
    <TranslatedMessage id="selectReportCategoryPrompt" />
    <Select<Category> value={value} onChange={onChange}>
      <Select.Option value="PersonalData">
        <TranslatedMessage id="PersonalData" />
      </Select.Option>
      <Select.Option value="Vaccination">
        <TranslatedMessage id="Vaccination" />
      </Select.Option>
      <Select.Option value="Pass">
        <TranslatedMessage id="Pass" />
      </Select.Option>
    </Select>
  </Centered>
);
export default SelectReportCategory;
