import { Button, Card, Form, Result, Select, Space, Typography } from 'antd';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import undraw_Calendar from './../../assets/undraw_medical_research.svg';
import { updateAccountAction } from '../../shared/state/Login';
import { TranslatedMessage } from '../../translations/data';
import { useAccount, useDelegations, useGouvernorats } from './queries';
import styled from 'styled-components';

type FormValues = {
  etat: string;
  delegationByIdDlg: string;
};

const SelectDelegation = styled.div`
  min-width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  gap: 24px;
  & > .ant-row {
    flex: 1;
  }
`;

const ConfirmRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const FullSpace = styled(Space)`
  width: 100%;
`;

const DelegationForm = () => {
  const intl = useIntl();
  const account = useAccount();
  const dispatch = useDispatch();
  const [idGouv, setIDGouv] = React.useState(account?.idGouv);
  const delegationsQuery = useDelegations(idGouv);
  const gouvernoratsQuery = useGouvernorats();

  return (
    <Card>
      <FullSpace size={32} direction="vertical" align="center">
        <Result
          icon={
            <img
              src={undraw_Calendar}
              alt=""
              style={{
                width: '100%',
                height: '15rem',
                textAlign: 'center',
              }}
            />
          }
          title={<TranslatedMessage id="pendingRdvDate" />}
        />
        <Typography.Text style={{ color: '#d80000' }}>
          <TranslatedMessage id="adressUpdateMessage" />
        </Typography.Text>
        <Form<FormValues>
          name="basic"
          initialValues={{
            etat: account?.libGouv,
            delegationByIdDlg: account?.libDeleg,
          }}
          onValuesChange={(_, vs) => {
            setIDGouv(vs.etat);
          }}
          onFinish={(values) =>
            dispatch(
              updateAccountAction({
                delegationByIdDlg: values.delegationByIdDlg,
              })
            )
          }
        >
          <SelectDelegation>
            <Form.Item
              name="etat"
              label={<TranslatedMessage id="Etat" />}
              hasFeedback
              required
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'GovErrMessage' }),
                },
              ]}
            >
              <Select loading={gouvernoratsQuery.isLoading} allowClear>
                {gouvernoratsQuery.data?.map((gov) => (
                  <Select.Option key={gov.idGouv} value={gov.idGouv}>
                    {gov.libGouv}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="delegationByIdDlg"
              label={<TranslatedMessage id="Ville" />}
              hasFeedback
              required
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'DelgationErrMessage',
                  }),
                },
              ]}
            >
              <Select loading={delegationsQuery.isLoading} allowClear>
                {delegationsQuery.data?.map((gov) => (
                  <Select.Option key={gov.idDlg} value={gov.idDlg}>
                    {gov.libDlg}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </SelectDelegation>
          <ConfirmRow>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                <TranslatedMessage id="Confirm" />
              </Button>
            </Form.Item>
          </ConfirmRow>
        </Form>
      </FullSpace>
    </Card>
  );
};

export default DelegationForm;
