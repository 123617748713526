import * as React from "react";
import { useIntl } from "react-intl";
import { Input, Modal, Radio, Space } from "antd";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { TranslatedMessage } from "../../translations/data";
import { useDispatch, useSelector } from "react-redux";
import { cancelInscriptionAction } from "../../shared/state/Login";
import { loadable } from "../../shared/types";

type Props = {
  visible: boolean;
  close: () => void;
};

type RadioValues =
  | "death"
  | "vaccinated abroad"
  | "vaccinated locally"
  | "other";

const CancelModal = ({ visible, close }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const loading = useSelector((s) =>
    loadable.isLoading(s.auth.cancelInscriptionResult)
  );
  const [selection, setSelection] = React.useState<RadioValues>();
  const [otherReason, setOtherReason] = React.useState<string>();

  const confirm = () =>
    Modal.confirm({
      title: intl.formatMessage({ id: "AnnulationDeInscreption" }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({ id: "cancelINS" }),
      okText: intl.formatMessage({ id: "Confirm" }),
      cancelText: intl.formatMessage({ id: "Cancel" }),
      okType: "danger",
      onOk: () => {
        if (!!selection && selection !== "other") {
          dispatch(
            cancelInscriptionAction(
              intl.formatMessage({
                id: selection,
              })
            )
          );
        } else if (selection === "other" && !!otherReason) {
          dispatch(cancelInscriptionAction(otherReason));
        }
      },
      okButtonProps: { loading: loading },
    });

  return (
    <Modal
      visible={visible}
      onCancel={close}
      cancelText={<TranslatedMessage id="Cancel" />}
      okText={<TranslatedMessage id="Confirm" />}
      onOk={() => confirm()}
      okButtonProps={{
        disabled: !selection || (selection === "other" && !otherReason),
      }}
    >
      <Radio.Group
        onChange={(e) => setSelection(e.target.value)}
        value={selection}
      >
        <Space direction="vertical">
          <Radio value="death">
            <TranslatedMessage id="death" />
          </Radio>
          <Radio value="vaccinated abroad">
            <TranslatedMessage id="vaccinated abroad" />
          </Radio>
          <Radio value="vaccinated locally">
            <TranslatedMessage id="vaccinated locally" />
          </Radio>
          <Radio value="other">
            <TranslatedMessage id="other" />{" "}
            <Input
              disabled={selection !== "other"}
              value={otherReason}
              onChange={(e) => setOtherReason(e.target.value)}
            />
          </Radio>
        </Space>
      </Radio.Group>
    </Modal>
  );
};

export default CancelModal;
