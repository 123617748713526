import { withFallback } from 'io-ts-types';
import * as t from 'io-ts';
import {
  Action,
  actionCreator,
  FailableAction,
  failableActionCreator,
  Loadable,
  PayloadAction,
  payloadActionCreator,
} from '../../types';

export type Login = {
  captcha: string;
  username: string;
  password: string;
};

export type PhoneChange = { phoneNumber: string; otp: number };

const orUndefined = <C extends t.Mixed>(c: C) =>
  withFallback(t.union([c, t.undefined]), undefined);

const stringOrUndefined = orUndefined(t.string);

export const AccountInfo = t.type(
  {
    codeInscription: stringOrUndefined,
    prenomInscrit: stringOrUndefined,
    nomInscrit: stringOrUndefined,
    libSexe: stringOrUndefined,
    cinInscrit: stringOrUndefined,
    libGouv: stringOrUndefined,
    dnInscrit: stringOrUndefined,
    canCancelAppointment: withFallback(t.boolean, false),
    canCancelInscription: withFallback(t.boolean, false),
    libDeleg: stringOrUndefined,
    idGouv: stringOrUndefined,
    dateCovid: stringOrUndefined,
    language: withFallback(
      t.keyof({ AR: null, FR: null, EN: null }, 'Language'),
      'AR'
    ),
  },
  'AccountInfo'
);
export type AccountInfo = t.TypeOf<typeof AccountInfo>;
export const emptyAccountInfo: AccountInfo = {
  codeInscription: undefined,
  prenomInscrit: undefined,
  nomInscrit: undefined,
  libSexe: undefined,
  cinInscrit: undefined,
  libGouv: undefined,
  dnInscrit: undefined,
  canCancelAppointment: false,
  canCancelInscription: false,
  libDeleg: undefined,
  idGouv: undefined,
  dateCovid: undefined,
  language: 'AR',
};

export type State = {
  preAuthResult: Loadable<PreAuthResult>;
  loginResult: Loadable<any>;
  account: Loadable<AccountInfo>;
  updateAccount: Loadable<any>;
  sexe: Loadable<Sexe[]>;
  preChangePhoneResult: Loadable<unknown>;
  confirmPhoneResult: Loadable<unknown>;
  cancelInscriptionResult: Loadable<unknown>;
  updateLanguageResult: Loadable<any>;
  changeRdvResult: Loadable<any>;
};

export type UpdateAccount = {
  delegationByIdDlg: string;
};
export type Sexe = {
  codeSexe: number;
  libSexe: string;
};

export const Gov = t.type(
  {
    idGouv: t.string,
    libGouv: t.string,
  },
  'Gov'
);
export type Gov = t.TypeOf<typeof Gov>;

export const Del = t.type(
  {
    idDlg: t.string,
    libDlg: t.string,
  },
  'Delegation'
);
export type Del = t.TypeOf<typeof Del>;

export const PreAuthResult = t.union(
  [
    t.type({ otp: t.string, phone: t.string, email: t.undefined }),
    t.type({ otp: t.string, phone: t.undefined, email: t.string }),
  ],
  'PreAuthResult'
);
export type PreAuthResult = t.TypeOf<typeof PreAuthResult>;

export type LoginAction = PayloadAction<'ACCOUNT/login', Login>;
export const loginAction = payloadActionCreator<LoginAction>('ACCOUNT/login');

export type LoginResultAction = FailableAction<'ACCOUNT/login_result', unknown>;
export const loginResultAction = failableActionCreator<LoginResultAction>(
  'ACCOUNT/login_result'
);

export type PreAuthAction = PayloadAction<'ACCOUNT/preAuth', string>;
export const preAuthAction = payloadActionCreator<PreAuthAction>(
  'ACCOUNT/preAuth'
);

export type PreAuthActionResultAction = FailableAction<
  'ACCOUNT/preAuth_result',
  PreAuthResult
>;
export const preAuthActionResultAction = failableActionCreator<PreAuthActionResultAction>(
  'ACCOUNT/preAuth_result'
);

export type ConfirmPhoneAction = PayloadAction<
  'ACCOUNT/confirmPhone',
  PhoneChange
>;
export const confirmPhoneAction = payloadActionCreator<ConfirmPhoneAction>(
  'ACCOUNT/confirmPhone'
);

export type ConfirmPhoneResultAction = FailableAction<
  'ACCOUNT/confirmPhone_result',
  unknown
>;
export const confirmPhoneResultAction = failableActionCreator<ConfirmPhoneResultAction>(
  'ACCOUNT/confirmPhone_result'
);

export type PreChangePhoneNumberAction = PayloadAction<
  'ACCOUNT/preChangePhoneNumber',
  string
>;
export const preChangePhoneNumberAction = payloadActionCreator<PreChangePhoneNumberAction>(
  'ACCOUNT/preChangePhoneNumber'
);

export type PreChangePhoneNumberActionResultAction = FailableAction<
  'ACCOUNT/preChangePhoneNumber_result',
  unknown
>;
export const preChangePhoneNumberActionResultAction = failableActionCreator<PreChangePhoneNumberActionResultAction>(
  'ACCOUNT/preChangePhoneNumber_result'
);

export type GetAccountAction = Action<'ACCOUNT/GetAccount'>;
export const getAccountAction = actionCreator<GetAccountAction>(
  'ACCOUNT/GetAccount'
);

export type GetAccountResultAction = FailableAction<
  'ACCOUNT/GetAccount_result',
  any
>;
export const getAccountResultAction = failableActionCreator<GetAccountResultAction>(
  'ACCOUNT/GetAccount_result'
);

export type GetVacAction = Action<'ACCOUNT/GetVac'>;
export const getVacAction = actionCreator<GetVacAction>('ACCOUNT/GetVac');

export type GetVacResultAction = FailableAction<'ACCOUNT/GetVac_result', any>;
export const getVacResultAction = failableActionCreator<GetVacResultAction>(
  'ACCOUNT/GetVac_result'
);

export type CancelInscriptionAction = PayloadAction<
  'ACCOUNT/CancelInscription',
  string
>;
export const cancelInscriptionAction = payloadActionCreator<CancelInscriptionAction>(
  'ACCOUNT/CancelInscription'
);

export type CancelInscriptionResultAction = FailableAction<
  'ACCOUNT/CancelInscription_result',
  any
>;
export const cancelInscriptionResultAction = failableActionCreator<CancelInscriptionResultAction>(
  'ACCOUNT/CancelInscription_result'
);
export type ChangeRdvAction = Action<'ACCOUNT/ChangeRdv'>;
export const changeRdvAction = actionCreator<ChangeRdvAction>(
  'ACCOUNT/ChangeRdv'
);

export type ChangeRdvResultAction = FailableAction<
  'ACCOUNT/ChangeRdv_result',
  any
>;
export const changeRdvResultAction = failableActionCreator<ChangeRdvResultAction>(
  'ACCOUNT/ChangeRdv_result'
);

export type UpdateAccountAction = PayloadAction<'ACCOUNT/UpdateAccount', any>;
export const updateAccountAction = payloadActionCreator<UpdateAccountAction>(
  'ACCOUNT/UpdateAccount'
);

export type UpdateAccountResultAction = FailableAction<
  'ACCOUNT/UpdateAccount_result',
  any
>;
export const updateAccountResultAction = failableActionCreator<UpdateAccountResultAction>(
  'ACCOUNT/UpdateAccount_result'
);

export type GetSexeAction = Action<'ACCOUNT/GetSexe'>;
export const getSexeAction = actionCreator<GetSexeAction>('ACCOUNT/GetSexe');

export type GetSexeResultAction = FailableAction<
  'ACCOUNT/GetSexe_result',
  Sexe[]
>;
export const getSexeResultAction = failableActionCreator<GetSexeResultAction>(
  'ACCOUNT/GetSexe_result'
);

export type LogoutAction = Action<'ACCOUNT/logout'>;
export const logoutAction = actionCreator<LogoutAction>('ACCOUNT/logout');

export type LogoutResultAction = FailableAction<
  'ACCOUNT/logout_result',
  undefined
>;
export const logoutResultAction = failableActionCreator<LogoutResultAction>(
  'ACCOUNT/logout_result'
);
export type UpdateLanguageAction = PayloadAction<
  'Language/UpdateLanguage',
  any
>;
export const updateLanguageAction = payloadActionCreator<UpdateLanguageAction>(
  'Language/UpdateLanguage'
);

export type UpdateLanguageResultAction = FailableAction<
  'Language/UpdateLanguage_result',
  any
>;
export const updateLanguageResultAction = failableActionCreator<UpdateLanguageResultAction>(
  'Language/UpdateLanguage_result'
);

export type Actions =
  | LoginAction
  | LoginResultAction
  | PreAuthActionResultAction
  | PreAuthAction
  | LogoutAction
  | LogoutResultAction
  | GetAccountAction
  | GetAccountResultAction
  | UpdateAccountAction
  | UpdateAccountResultAction
  | GetSexeAction
  | GetSexeResultAction
  | ConfirmPhoneAction
  | PreChangePhoneNumberAction
  | ConfirmPhoneResultAction
  | PreChangePhoneNumberActionResultAction
  | CancelInscriptionAction
  | CancelInscriptionResultAction
  | GetVacAction
  | GetVacResultAction
  | ChangeRdvAction
  | ChangeRdvResultAction
  | UpdateLanguageAction
  | UpdateLanguageResultAction;
