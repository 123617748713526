import styled from 'styled-components';
import { TranslatedMessage } from '../../translations/data';
import { Select, Input } from 'antd';

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  & > .ant-select {
    flex: 1;
    min-width: 160px;
  }
  & > input {
    flex: 3;
  }
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
  & > ${InputRow} {
    width: 100%;
  }
`;

type Props = {
  value?: { type?: number; contenu?: string };
  onChange?: (_: { type?: number; contenu?: string }) => void;
};

const PersonalDataComplaint = ({ value, onChange }: Props) => (
  <Centered>
    <TranslatedMessage id="personalDataComplaintPrompt" />
    <InputRow>
      <Select
        value={value?.type}
        onChange={(type) => onChange?.({ type, contenu: value?.contenu })}
      >
        <Select.Option value={36}>
          <TranslatedMessage id="NomComplet" />
        </Select.Option>
        <Select.Option value={37}>
          <TranslatedMessage id="birthday" />
        </Select.Option>
      </Select>
      <Input
        value={value?.contenu}
        onChange={(e) =>
          onChange?.({ type: value?.type, contenu: e.target.value })
        }
      />
    </InputRow>
  </Centered>
);

export default PersonalDataComplaint;
