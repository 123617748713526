// @ts-nocheck
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Radio,
  Row,
  Typography,
} from 'antd';
import { pipe } from 'fp-ts/lib/pipeable';
import React, { useState } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import {
  getDeclarationAction,
  postDeclarationAction,
  SideEffectsResponse,
  updateDeclarationAction,
  emptySideEffectResponse,
} from '../../shared/state/rapports';
import { State } from '../../shared/state/store';
import { loadable } from '../../shared/types';
import { TranslatedMessage } from '../../translations/data';
import { AccountInfo, emptyAccountInfo } from './../../shared/state/Login';
import { isCin } from '../../shared/regex';

const TitleWithoutMargin = styled(Typography.Title)`
  margin-bottom: 0;
`;
const mapStateToProps = ({
  auth: { account },
  reports: { declaration },
}: State) => ({
  accountInfo: pipe(
    account,
    loadable.getOrElse((): AccountInfo => emptyAccountInfo)
  ),
  declarationInfo: pipe(
    declaration,
    loadable.getOrElse((): SideEffectsResponse => emptySideEffectResponse)
  ),
});

const mapDispatchToProps = {
  getDeclaration: getDeclarationAction,
  postDeclaration: postDeclarationAction,
  updateDeclaration: updateDeclarationAction,
};
type OwnProps = {
  disabled?: boolean;
  setVisible: () => void;
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

const SideEffectsForm = ({
  accountInfo,
  declarationInfo,
  postDeclaration,
  updateDeclaration,
  disabled,
  setVisible,
}: Props) => {
  const [form] = Form.useForm();
  const loading = useSelector((s: State) =>
    loadable.isLoading(s.reports.declaration)
  );
  const onFinish = (values: any) => {
    const result = {
      addressPriseEnCharge: values.addressPriseEnCharge,

      dateHDebutMapi: values.dateHDebutMapi,

      descptMapi: `${values.Diabète ? 'Diabète ,' : ''}${
        values.HypertensionArtérielle ? 'HypertensionArtérielle ,' : ''
      }${values.descptMapi}`,

      maladieChron:
        values.Diabète || values.HypertensionArtérielle ? true : false,
      nomPriseEnCharge: values.nomPriseEnCharge,

      priseEnChargeReaction: values.priseEnChargeReaction,
      reactionPostVaccinale: values.reactionPostVaccinale,
      telPriseEnCharge: values.telPriseEnCharge,
    };
    postDeclaration(result);
    localStorage.setItem('lastSideEffectPost', `${Date.now()}`);
    // console.log(result);
    form.resetFields();
    setTimeout(() => {
      setVisible();
    }, 1000);
  };

  return (
    <Form
      labelAlign="left"
      form={form}
      name="register"
      onFinish={onFinish}
      scrollToFirstError
      initialValues={{
        addressPriseEnCharge: declarationInfo?.addressPriseEnCharge,

        dateHDebutMapi: declarationInfo?.dateHDebutMapi
          ? moment(declarationInfo?.dateHDebutMapi)
          : null,

        descptMapi: declarationInfo?.descptMapi
          ?.replace('Diabète ,', '')
          .replace('HypertensionArtérielle ,', ''),

        maladieChron: declarationInfo?.maladieChron,
        nomPriseEnCharge: declarationInfo?.nomPriseEnCharge,
        HypertensionArtérielle: declarationInfo?.descptMapi?.includes(
          'HypertensionArtérielle'
        ),
        Diabète: declarationInfo?.descptMapi?.includes('Diabète'),
        priseEnChargeReaction: declarationInfo?.priseEnChargeReaction,
        reactionPostVaccinale: declarationInfo?.reactionPostVaccinale,
        telPriseEnCharge: declarationInfo?.telPriseEnCharge,
      }}
    >
      <Collapse defaultActiveKey={['1']} ghost expandIconPosition="right">
        <Collapse.Panel
          header={
            <Typography.Title level={5}>
              <TranslatedMessage id="Patient" />
            </Typography.Title>
          }
          key="1"
        >
          <Card>
            <Descriptions column={{ sm: 2, xs: 1 }}>
              <Descriptions.Item
                label={
                  <TitleWithoutMargin level={5}>
                    <TranslatedMessage id="NomComplet" />
                  </TitleWithoutMargin>
                }
              >
                {`${declarationInfo?.prenomPt} ${declarationInfo?.nomPt}`}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <TitleWithoutMargin level={5}>
                    <TranslatedMessage id="Sexe" />
                  </TitleWithoutMargin>
                }
              >
                {`${declarationInfo?.genrePt}`}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  isCin.test(declarationInfo?.cinp) ?
                  <TitleWithoutMargin level={5}>
                    <TranslatedMessage id="NumeroCIN" />
                  </TitleWithoutMargin>:
                  <TitleWithoutMargin level={5}>
                    <TranslatedMessage id="Passport" />
                  </TitleWithoutMargin>
                }
              >
                {declarationInfo?.cinp}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <TitleWithoutMargin level={5}>
                    <TranslatedMessage id="TypeIdentifiant" />
                  </TitleWithoutMargin>
                }
              >
                {declarationInfo?.typeIdentifiant
                  ? declarationInfo?.typeIdentifiant
                  : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <TitleWithoutMargin level={5}>
                    {' '}
                    <TranslatedMessage id="Adresse" />
                  </TitleWithoutMargin>
                }
              >
                {declarationInfo?.adressePt
                  ? declarationInfo?.adressePt
                  : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <TitleWithoutMargin level={5}>
                    <TranslatedMessage id="Gouvernorat" />
                  </TitleWithoutMargin>
                }
              >
                {declarationInfo?.idGouv ? declarationInfo?.idGouv : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <TitleWithoutMargin level={5}>
                    <TranslatedMessage id="birthday" />
                  </TitleWithoutMargin>
                }
              >
                {declarationInfo?.dateNaissPt ? (
                  <FormattedDate
                    value={declarationInfo?.dateNaissPt}
                    day="numeric"
                    month="long"
                    year="numeric"
                  />
                ) : (
                  'N/A'
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <TitleWithoutMargin level={5}>
                    {' '}
                    <TranslatedMessage id="email" />
                  </TitleWithoutMargin>
                }
              >
                {declarationInfo?.mailPt ? declarationInfo?.mailPt : 'N/A'}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Collapse.Panel>
        <Collapse.Panel
          header={
            <Typography.Title level={5}>
              <TranslatedMessage id="vaccin" />
            </Typography.Title>
          }
          key="4"
        >
          <Card>
            <Descriptions column={{ sm: 1, xs: 1 }} style={{ width: '100%' }}>
              <Descriptions.Item
                label={
                  <TitleWithoutMargin level={5}>
                    <TranslatedMessage id="DateDevacc" />
                  </TitleWithoutMargin>
                }
              >
                <FormattedDate
                  value={declarationInfo?.dateHVaccin}
                  day="numeric"
                  month="long"
                  year="numeric"
                />
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <TitleWithoutMargin level={5}>
                    <TranslatedMessage id="Hour" />
                  </TitleWithoutMargin>
                }
              >
                {' '}
                <FormattedTime value={declarationInfo?.dateHVaccin} />
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <TitleWithoutMargin level={5}>
                    <TranslatedMessage id="injectionSite" />
                  </TitleWithoutMargin>
                }
              >
                {declarationInfo?.lieuVaccin}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <TitleWithoutMargin level={5}>
                    <TranslatedMessage id="NomVaccin" />
                  </TitleWithoutMargin>
                }
              >
                {declarationInfo?.nomVaccinFab}
              </Descriptions.Item>
              <Row style={{ width: '100%' }}>
                <Form.Item
                  name="dateHDebutMapi"
                  label={<TranslatedMessage id="reactionDtae" />}
                >
                  <DatePicker style={{ width: '100%' }} placeholder="" />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item label={<TranslatedMessage id="vacReaction" />}>
                  <Form.Item
                    name="injectionSite"
                    label={<TranslatedMessage id="injectionSite" />}
                    valuePropName="checked"
                  >
                    <Checkbox style={{ lineHeight: '32px' }} />
                  </Form.Item>
                  <Form.Item
                    name="reactionPostVaccinale"
                    label={<TranslatedMessage id="AutresAPréciser" />}
                  >
                    <Input.TextArea rows={1} />
                  </Form.Item>
                </Form.Item>
              </Row>
            </Descriptions>
          </Card>
        </Collapse.Panel>

        <Collapse.Panel
          header={
            <Typography.Title level={5}>
              <TranslatedMessage id="AntécédentsMédicaux" />
            </Typography.Title>
          }
          key="2"
        >
          <Card>
            <Row justify="space-around">
              <Form.Item
                name="HypertensionArtérielle"
                label={<TranslatedMessage id="HypertensionArtérielle" />}
                valuePropName="checked"
              >
                <Checkbox style={{ lineHeight: '32px' }} />
              </Form.Item>
              <Form.Item
                name="Diabète"
                label={<TranslatedMessage id="Diabète" />}
                valuePropName="checked"
              >
                <Checkbox style={{ lineHeight: '32px' }} />
              </Form.Item>
              <Form.Item
                name="descptMapi"
                label={<TranslatedMessage id="AutresAPréciser" />}
              >
                <Input.TextArea rows={1} />
              </Form.Item>
            </Row>
          </Card>
        </Collapse.Panel>

        <Collapse.Panel
          header={
            <Typography.Title level={5}>
              <TranslatedMessage id="priseEnCharge" />
            </Typography.Title>
          }
          key="3"
        >
          <Card>
            <Row justify="space-between">
              <Form.Item
                name="priseEnChargeReaction"
                label={<TranslatedMessage id="consultationMed" />}
              >
                <Radio.Group>
                  <Radio value={true}>
                    <TranslatedMessage id="YES" />
                  </Radio>
                  <Radio value={false}>
                    <TranslatedMessage id="NON" />
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Row>
            <Row justify="space-between">
              <Form.Item
                name="nomPriseEnCharge"
                label={<TranslatedMessage id="medcinTitle" />}
              >
                <Input />
              </Form.Item>
            </Row>
            <Row justify="space-between">
              <Form.Item
                name="addressPriseEnCharge"
                label={<TranslatedMessage id="medAdress" />}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="telPriseEnCharge"
                label={<TranslatedMessage id="phoneNumber" />}
              >
                <Input />
              </Form.Item>
            </Row>
          </Card>
        </Collapse.Panel>
      </Collapse>
      <Form.Item>
        <Button block type="primary" loading={loading} htmlType="submit">
          <TranslatedMessage id="Submit" />
        </Button>
      </Form.Item>
    </Form>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SideEffectsForm);
