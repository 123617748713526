import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Image, Layout, Row, Tooltip } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import styled from "styled-components";
import DropdownMenu from "../../components/reports/SideMenu";
import { TranslatedMessage } from "../../translations/data";
import LocaleSelector from "../LocaleSelector";

import { logoutAction } from "../state/Login";
import { State } from "../state/store";
import logo from "./../../assets/logoMS2.png";
import logoar from "./../../assets/logo-Arabe.png";
import GreenphoneCard from "./GreenphoneCard";

const mapStateToProps = ({ router: { location }, locale }: State) => ({
  locale: locale,
});

type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: bindActionCreators(logoutAction, dispatch),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps;

const StyledHeader = styled(Layout.Header)`
  height: 100%;
`;

const Header = ({ logout, locale }: Props) => (
  <StyledHeader>
    <Row justify="space-between" align="middle">
      <Col lg={12} xs={24}>
        <Row align="middle" gutter={[16, 16]}>
          <Col lg={8} xs={8}>
            <Row justify="space-between" align="middle">
              <Image
                preview={false}
                src={locale === "fr" ? logo : logoar}
                style={{
                  height: "auto",
                  /* even more control with max-width */
                  maxWidth: "200px",
                }}
              />
            </Row>
          </Col>
          <Col lg={16} xs={12}>
            <GreenphoneCard />
          </Col>
          <Col lg={0} xs={4}>
            <Tooltip placement="left" title={<TranslatedMessage id="LOGOUT" />}>
              <Button
                size="large"
                shape="circle"
                icon={
                  <Avatar
                    style={{ background: "transparent", fontSize: "16px" }}
                  >
                    <LogoutOutlined style={{ color: "#d80000" }} />
                  </Avatar>
                }
                onClick={logout}
              ></Button>
            </Tooltip>
          </Col>
        </Row>
      </Col>
      <Col lg={12} xs={24}>
        <Row justify="end" align="middle" gutter={[16, 16]}>
          <Col>
            <DropdownMenu key="more" />
          </Col>
          <Col>
            <LocaleSelector />
          </Col>

          <Col xs={0} lg={2}>
            <Tooltip placement="left" title={<TranslatedMessage id="LOGOUT" />}>
              <Button
                shape="circle"
                size="large"
                icon={
                  <Avatar
                    style={{ background: "transparent", fontSize: "16px" }}
                  >
                    <LogoutOutlined style={{ color: "#d80000" }} />
                  </Avatar>
                }
                onClick={logout}
              ></Button>
            </Tooltip>
          </Col>
        </Row>
      </Col>
    </Row>
  </StyledHeader>
);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
