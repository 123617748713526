import { Spin } from 'antd';
import { pipe } from 'fp-ts/lib/pipeable';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import styled from 'styled-components';

import { State } from '../../shared/state/store';
import { loadable } from '../../shared/types';

export const FullPageSpin = styled(Spin)`
  height: calc(100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const mapStateToProps = ({ auth: { account } }: State) => ({
  isLoggedOut: pipe(account, loadable.caseOf({ Ok: () => false, Loading: () => false, _: () => true })),
  isLoading: loadable.isLoading(account) || loadable.isNotRequested(account),
});

type Props = RouteProps & ReturnType<typeof mapStateToProps>;

const PrivateRoute = ({ isLoading, isLoggedOut, ...rest }: Props) => {
  const intl = useIntl();

  return isLoggedOut ? (
    <Redirect to="/auth" />
  ) : isLoading ? (
    <FullPageSpin tip={intl.formatMessage({ id: 'loading' })} />
  ) : (
    <Route {...rest} />
  );
};

export default connect(mapStateToProps)(PrivateRoute);
