import { Card, Descriptions, PageHeader, Space, Typography } from 'antd';
import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import moment from 'moment';
import { TranslatedMessage } from '../../translations/data';
import CancelInscription from './CancelInscription';
import ModifyInfoDrawer from './ModifyInfoDrawer';
import StartSponsorship from '../Sponsorship/StartSponsorship';
import { useAccount } from '../Home/queries';
import { isCin } from '../../shared/regex';

const TitleWithoutMargin = styled(Typography.Text)`
  margin-bottom: 0;
  margin-right: 2rem;
`;
const UserInfoCard = () => {
  const account = useAccount();
  const intl = useIntl();

  return (
    <Card>
      <PageHeader
        title={<TranslatedMessage id="PersonalInfo" />}
        extra={[
          <Space key={account?.codeInscription} align="center" size={16}>
            {/* <ModifyInfoDrawer /> */}
            <StartSponsorship />
            <CancelInscription key="1" />
          </Space>,
        ]}
      >
        <Descriptions column={{ xxl: 4, xl: 4, lg: 3, md: 1, sm: 1, xs: 1 }}>
          <Descriptions.Item
            label={
              <TitleWithoutMargin strong>
                <TranslatedMessage id="nevax" />
              </TitleWithoutMargin>
            }
          >
            {account?.codeInscription}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <TitleWithoutMargin strong>
                <TranslatedMessage id="NomComplet" />
              </TitleWithoutMargin>
            }
          >
            {`${account?.prenomInscrit ?? ''} ${account?.nomInscrit ?? ''}`}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              //@ts-ignore
              isCin.test(account?.cinInscrit) ?
              <TitleWithoutMargin strong>
                <TranslatedMessage id="NumeroCIN" />
              </TitleWithoutMargin> :  <TitleWithoutMargin strong>
                <TranslatedMessage id="Passport" />
              </TitleWithoutMargin>
            }
          >
            {account?.cinInscrit}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <TitleWithoutMargin strong>
                <TranslatedMessage id="birthday" />
              </TitleWithoutMargin>
            }
          >
            {account?.dnInscrit
              ? intl.formatDate(moment(account?.dnInscrit).format(), {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                })
              : 'N/A'}
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
    </Card>
  );
};
export default UserInfoCard;
