import { Questionresponse } from './types';
import * as t from 'io-ts';

import * as axios from '../../axios';

export const getResponse = (cancelToken?: axios.CancelToken) =>
  axios.get(`/evax/api/questions/reponse/me`, { cancelToken, decoder: t.any });

export const updateResponse = (
  updateAccount: Questionresponse,
  cancelToken?: axios.CancelToken
) =>
  axios.put(`/evax/api/question/reponse`, updateAccount, {
    cancelToken,
    decoder: t.any,
  });
