import * as React from 'react';
import styled from 'styled-components';

import UserInfoCard from './../account/UserInfoCard';
import Dossier from './Dossier';

const BaseLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
`;

const Home = () => {
  return (
    <BaseLayout>
      <UserInfoCard />
       <Dossier />
    </BaseLayout>
  );
};

export default Home;
