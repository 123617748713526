import { Result, Space, Typography } from 'antd';
import { FileAddFilled } from '@ant-design/icons';
import * as React from 'react';
import { TranslatedMessage } from '../../translations/data';
import VaccineCertificateDownload from '../VaccineCertificateDownload';
import VaccinePassportDownload from '../VaccinePassportDownload';
import { ReactComponent as EU } from '../../assets/european-union-flag.svg';
import { FaSyringeIcon } from '../../shared/constants';
import { ReactComponent as Passport } from '../../assets/carte-passeport.svg';
import VaccineGrippeCertificateDownload from '../VaccineGrippeCertificateDownload';

const Passes = (props) => {  
  const vacinType = props.vacinType
  return (
    <div className='row'>
    {
      vacinType === 'grippe' ? 
      ( <Space direction = "vertical" size="middle" align="center" style={{width:'calc(100vw - 100px)' ,height:"300px",justifyContent:"center"}}>
      <Typography.Title level={5}>
        <FileAddFilled style={{ color: '#d80000' }} />{' '}
        <TranslatedMessage id="Medicaldoc_Grippe" />
      </Typography.Title>
      <Space size={4} align="center">
        <Result icon={<FaSyringeIcon />} extra={<VaccineGrippeCertificateDownload />} />
       </Space>
       </Space>)
      :
      <Space direction="vertical" size={2} align="center">
    <Typography.Title level={5}>
      <FileAddFilled style={{ color: '#d80000' }} />{' '}
      <TranslatedMessage id="Medicaldoc_Covid" />
    </Typography.Title>
    <Space size={3}>
      <Result icon={<FaSyringeIcon />} extra={<VaccineCertificateDownload />} />
      <Result
        icon={
          <span className="anticon">
            <Passport style={{ height: '72px', fill: '#d80000' }} />
          </span>
        }
        extra={<VaccinePassportDownload />}
      />
      <Result
        icon={
          <span className="anticon">
            <EU style={{ height: '72px' }} />
          </span>
        }
        extra={<VaccinePassportDownload eu />}
      />
    </Space>
  </Space>
    }
    </div >
  );
      }

export default Passes;
