import { AxiosError } from 'axios';
import { either, option, task } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import { useQuery, UseQueryResult } from 'react-query';
import {
  getBalance,
  getDelegations,
  getGouvernorats,
  getBalanceGrippe
} from '../../shared/state/Login/api';
import { getCertif, verifyGrippeCertif, getGrippeCertif } from '../../shared/state/rapports/api';
import { Appointments, HealthPass } from '../../shared/types/vaccin.type';
import * as axios from '../../shared/axios';
import { Certif } from '../../shared/state/rapports';
import { useSelector } from 'react-redux';
import { loadable } from '../../shared/types';
import { Del, Gov } from '../../shared/state/Login';

const isAxiosError = (err: unknown): err is AxiosError =>
  typeof err === 'object' && err !== null && err.hasOwnProperty('isAxiosError');

const is400Error = (err: unknown) =>
  isAxiosError(err) &&
  !!err.response?.status &&
  err.response.status >= 400 &&
  err.response.status < 500;

const defaultRetry = (c: number, err: unknown) => !is400Error(err) && c < 5;

export const useAccount = () =>
  useSelector((s) =>
    pipe(s.auth.account, loadable.toOption, option.toUndefined)
  );

const useDefaultOptions = () => {
  const account = useAccount();

  return {
    staleTime: 300000,
    retry: defaultRetry,
    enabled: !!account,
  };
};

export const convert400 = <T,>(ma: either.Either<unknown, T>): T | 'user error' =>
  pipe(
    ma,
    either.fold(
      (e) => {
        if (is400Error(e)) {
          return 'user error';
        }
        throw e;
      },
      (x): T | 'user error' => x
    )
  );

const userErrorAsErr = <T, Err>(
  res: UseQueryResult<T | 'user error', Err>
): UseQueryResult<T, Err> => {
  if (res.isSuccess && res.data === 'user error') {
    // @ts-ignore
    return {
      ...res,
      isSuccess: false,
      isError: true,
      data: undefined,
    };
  }

  // @ts-ignore
  return res;
};

export const useAppointments = () => {
  const options = useDefaultOptions();
  const account = useAccount();

  return userErrorAsErr<Appointments, unknown>(
    useQuery(
      [
        'appointments',
        options.enabled,
        account?.codeInscription,
        account?.language,
      ],
      () => pipe(getBalance(), task.map(convert400))(),
      options
    )
  );
};

export const useGrippeAppointments = () => {
  const options = useDefaultOptions();
  const account = useAccount();

  return userErrorAsErr<Appointments, unknown>(
    useQuery(
      [
        'grippe-appointments',
        options.enabled,
        account?.codeInscription,
        account?.language,
      ],
      () => pipe(getBalanceGrippe(), task.map(convert400))(),
      options
    )
  );
};

export const useCertificate = (
  lg: 'AR' | 'FR' | 'EN',
  { enabled = true, onSuccess = (_: Certif) => {} }
) => {
  const options = useDefaultOptions();
  const account = useAccount();

  return userErrorAsErr<Certif, unknown>(
    useQuery(
      ['certificate', lg, options.enabled, account?.codeInscription],
      () => pipe(getCertif(lg), task.map(convert400))(),
      {
        ...options,
        enabled,
        onSuccess: (c) => {
          if (c !== 'user error') {
            onSuccess(c);
          }
        },
      }
    )
  );
};

export const useGrippeCertificate = (
  lg: 'AR' | 'FR' | 'EN',
  { enabled = true, onSuccess = (_: Certif) => {} }
) => {
  const options = useDefaultOptions();
  const account = useAccount();

  return userErrorAsErr<Certif, unknown>(
    useQuery(
      ['grippeCertificate', lg, options.enabled, account?.codeInscription],
      () => pipe(getGrippeCertif(lg), task.map(convert400))(),
      {
        ...options,
        enabled,
        cacheTime:0,
        onSuccess: (c) => {
          if (c !== 'user error') {
            onSuccess(c);
          }
        },
      }
    )
  );
};

export const useGrippeCertificateVerification = (
  lg: 'AR' | 'FR' | 'EN',
  codeInscription,
  { enabled = true, onSuccess = (_: Certif) => {} }
) => {
  const options = useDefaultOptions();

  return userErrorAsErr<Certif, unknown>(
    useQuery(
      ['grippeCertificate', lg, options.enabled, codeInscription],
      () => pipe(verifyGrippeCertif(lg, codeInscription), task.map(convert400))(),
      {
        ...options,
        enabled: true,
        onSuccess: (c) => {
          if (c !== 'user error') {
            onSuccess(c);
          }
        },
      }
    )
  );
};


export const useHealthPass = ({ onSuccess }: { onSuccess?: () => void }) => {
  const options = useDefaultOptions();
  const account = useAccount();

  return userErrorAsErr<HealthPass, unknown>(
    useQuery(
      ['pass', options.enabled, account?.codeInscription],
      () =>
        pipe(
          axios.get('/evax/api/certif/health-pass', { decoder: HealthPass }),
          task.map(convert400)
        )(),
      { ...options, onSuccess }
    )
  );
};

export const useDelegations = (idGouv?: string) => {
  const options = useDefaultOptions();

  return userErrorAsErr<Del[], unknown>(
    useQuery(
      ['delegation', idGouv],
      () => pipe(getDelegations(idGouv ?? ''), task.map(convert400))(),
      { ...options, enabled: options.enabled && !!idGouv }
    )
  );
};

export const useGouvernorats = () => {
  const options = useDefaultOptions();

  return userErrorAsErr<Gov[], unknown>(
    useQuery(
      'gouvernorats',
      () => pipe(getGouvernorats(), task.map(convert400))(),
      options
    )
  );
};
