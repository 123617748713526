import { Spin } from 'antd';
import * as React from 'react';

export const FaSyringeIconLazy = React.lazy(() =>
  import('@fortawesome/react-fontawesome').then(({ FontAwesomeIcon }) =>
    import('@fortawesome/free-solid-svg-icons').then(({ faSyringe }) => ({
      default: () => <FontAwesomeIcon icon={faSyringe} />,
    }))
  )
);
export const FaSyringeIcon = ({ style }: { style?: React.CSSProperties }) => (
  <span style={style} className="anticon">
    <React.Suspense fallback={<Spin spinning={true} />}>
      <FaSyringeIconLazy />
    </React.Suspense>
  </span>
);
export const FaFileMedicallIconLazy = React.lazy(() =>
  import('@fortawesome/react-fontawesome').then(({ FontAwesomeIcon }) =>
    import('@fortawesome/free-solid-svg-icons').then(({ faFileMedical }) => ({
      default: () => <FontAwesomeIcon icon={faFileMedical} />,
    }))
  )
);
export const FaFileMedicallIcon = () => (
  <span className="anticon">
    <React.Suspense fallback={<Spin spinning={true} />}>
      <FaFileMedicallIconLazy />
    </React.Suspense>
  </span>
);

export const FaCalendarIconLazy = React.lazy(() =>
  import('@fortawesome/react-fontawesome').then(({ FontAwesomeIcon }) =>
    import('@fortawesome/free-solid-svg-icons').then(({ faCalendar }) => ({
      default: () => <FontAwesomeIcon icon={faCalendar} />,
    }))
  )
);
export const FaCalendarIcon = () => (
  <span className="anticon">
    <React.Suspense fallback={<Spin spinning={true} />}>
      <FaCalendarIconLazy />
    </React.Suspense>
  </span>
);
export const FaUserMdIconLazy = React.lazy(() =>
  import('@fortawesome/react-fontawesome').then(({ FontAwesomeIcon }) =>
    import('@fortawesome/free-solid-svg-icons').then(({ faUserMd }) => ({
      default: () => <FontAwesomeIcon icon={faUserMd} />,
    }))
  )
);
export const FaUserMdIcon = () => (
  <span className="anticon">
    <React.Suspense fallback={<Spin spinning={true} />}>
      <FaUserMdIconLazy />
    </React.Suspense>
  </span>
);

const FaClinicMedicalIconLazy = React.lazy(() =>
  import('@fortawesome/react-fontawesome').then(({ FontAwesomeIcon }) =>
    import('@fortawesome/free-solid-svg-icons').then(({ faClinicMedical }) => ({
      default: () => <FontAwesomeIcon icon={faClinicMedical} />,
    }))
  )
);

export const FaPrintIconLazy = React.lazy(() =>
  import('@fortawesome/react-fontawesome').then(({ FontAwesomeIcon }) =>
    import('@fortawesome/free-solid-svg-icons').then(({ faPrint }) => ({
      default: () => <FontAwesomeIcon icon={faPrint} />,
    }))
  )
);
export const FaPrintIcon = () => (
  <span className="anticon">
    <React.Suspense fallback={<Spin spinning={true} />}>
      <FaPrintIconLazy />
    </React.Suspense>
  </span>
);

export const FaClinicMedicalIcon = () => (
  <span className="anticon">
    <React.Suspense fallback={<Spin spinning={true} />}>
      <FaClinicMedicalIconLazy />
    </React.Suspense>
  </span>
);
export const FaInfoIconLazy = React.lazy(() =>
  import('@fortawesome/react-fontawesome').then(({ FontAwesomeIcon }) =>
    import('@fortawesome/free-solid-svg-icons').then(({ faInfo }) => ({
      default: () => <FontAwesomeIcon icon={faInfo} />,
    }))
  )
);
export const FaInfoIcon = () => (
  <span className="anticon">
    <React.Suspense fallback={<Spin spinning={true} />}>
      <FaInfoIconLazy />
    </React.Suspense>
  </span>
);

export const FaCommentMedicalIconLazy = React.lazy(() =>
  import('@fortawesome/react-fontawesome').then(({ FontAwesomeIcon }) =>
    import('@fortawesome/free-solid-svg-icons').then(
      ({ faCommentMedical }) => ({
        default: () => <FontAwesomeIcon icon={faCommentMedical} />,
      })
    )
  )
);
export const FaCommentMedicalIcon = () => (
  <span className="anticon">
    <React.Suspense fallback={<Spin spinning={true} />}>
      <FaCommentMedicalIconLazy />
    </React.Suspense>
  </span>
);

export const FaFileMedicalIconLazy = React.lazy(() =>
  import('@fortawesome/react-fontawesome').then(({ FontAwesomeIcon }) =>
    import('@fortawesome/free-solid-svg-icons').then(({ faFileMedical }) => ({
      default: () => <FontAwesomeIcon icon={faFileMedical} />,
    }))
  )
);
export const FaFileMedicalIcon = () => (
  <span className="anticon">
    <React.Suspense fallback={<Spin spinning={true} />}>
      <FaFileMedicalIconLazy />
    </React.Suspense>
  </span>
);

export const FaUserCheckIconLazy = React.lazy(() =>
  import('@fortawesome/react-fontawesome').then(({ FontAwesomeIcon }) =>
    import('@fortawesome/free-solid-svg-icons').then(({ faUserCheck }) => ({
      default: () => <FontAwesomeIcon icon={faUserCheck} />,
    }))
  )
);
export const FaUserCheckIcon = () => (
  <span className="anticon">
    <React.Suspense fallback={<Spin spinning={true} />}>
      <FaUserCheckIconLazy />
    </React.Suspense>
  </span>
);
