import { SnippetsFilled } from '@ant-design/icons';
import { Alert, Button, Dropdown, Menu, Space } from 'antd';
import React from 'react';
import { useReactToPrint } from 'react-to-print';

import { TranslatedMessage } from '../translations/data';
import { HealthPass } from '../shared/types/vaccin.type';
import VaccinePassportInfo from './VaccinePassportInfo';
import VaccinePassportEUInfo from './VaccinePassportEUInfo';
import DownloadAsImage from './DownloadAsImage';
import { useHealthPass } from './Home/queries';

type Props = {
  eu?: boolean;
  healthPass?: HealthPass;
};

const VaccinePassportDownload = ({ eu }: Props) => {
  const infoRef = React.useRef<VaccinePassportInfo>(null);
  const infoEURef = React.useRef<VaccinePassportEUInfo>(null);
  const imageRef = React.useRef<HTMLDivElement>(null);
  const healthPassQuery = useHealthPass({});

  const printPass = useReactToPrint({
    content: () => (eu ? infoEURef.current : infoRef.current),
  });

  return (
    <Space direction="vertical" align="center">
      <div style={{ display: 'none' }}>
        {healthPassQuery.isSuccess &&
          (eu ? (
            <VaccinePassportEUInfo
              healthPass={healthPassQuery.data}
              ref={infoEURef}
            />
          ) : (
            <VaccinePassportInfo
              healthPass={healthPassQuery.data}
              ref={infoRef}
            />
          ))}
      </div>
      <Dropdown
        disabled={healthPassQuery.isError}
        overlay={
          <Menu onClick={(e) => (e.key === 'PDF' ? printPass?.() : undefined)}>
            <Menu.Item>
              <DownloadAsImage
                imageRef={imageRef}
                disabled={healthPassQuery.isError}
                loading={healthPassQuery.isLoading}
                icon={<SnippetsFilled />}
              >
                <div style={{ width: '90vw' }} ref={imageRef}>
                  {healthPassQuery.isSuccess &&
                    (eu ? (
                      <VaccinePassportEUInfo
                        healthPass={healthPassQuery.data}
                      />
                    ) : (
                      <VaccinePassportInfo healthPass={healthPassQuery.data} />
                    ))}
                </div>
              </DownloadAsImage>
            </Menu.Item>
            <Menu.Item key="PDF">
              <Button type="text" icon={<SnippetsFilled />}>
                PDF
              </Button>
            </Menu.Item>
          </Menu>
        }
      >
        <Button
          disabled={healthPassQuery.isError}
          loading={healthPassQuery.isLoading}
          icon={<SnippetsFilled />}
        >
          <TranslatedMessage
            id={eu ? 'euPassVaccinalButton' : 'passVaccinalButton'}
          />
        </Button>
      </Dropdown>
      {healthPassQuery.isError && (
        <Alert
          message={<TranslatedMessage id="passVaccinalNonDisponible" />}
          type="error"
        />
      )}
    </Space>
  );
};

export default VaccinePassportDownload;
