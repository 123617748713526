import { useSelector } from 'react-redux';
import { pipe } from 'fp-ts/lib/function';
import { option } from 'fp-ts';
import { loadable } from './shared/types';

export const useCodeInscription = () =>
  useSelector((s) =>
    pipe(
      s.auth.account,
      loadable.map((acc) => acc.codeInscription),
      loadable.toOption,
      option.toUndefined
    )
  );
