import * as t from 'io-ts';

import * as axios from '../../axios';
import { Certif, ReclamationType, ReportRequestBody } from './types';

export const getAbus = (cancelToken?: axios.CancelToken) => {
  return axios.get(`/evax/api/abuses`, { cancelToken, decoder: t.any });
};

export const getReclamations = (cancelToken?: axios.CancelToken) =>
axios.get(`/evax/api/reclamations/type`, {
  cancelToken,
  decoder: t.array(ReclamationType),
});

export const getSideEffects = (cancelToken?: axios.CancelToken) => {
  return axios.get(`/evax/api/pharmaco`, { cancelToken, decoder: t.any });
};

export const postAbus = (
  user: ReportRequestBody,
  cancelToken?: axios.CancelToken
) => {
  return axios.post(`/evax/api/abuses`, user, { cancelToken, decoder: t.any });
};

export const updateAbus = (
  updateAccount: ReportRequestBody,
  cancelToken?: axios.CancelToken
) => {
  return axios.put(`/evax/api/abuses`, updateAccount, {
    cancelToken,
    decoder: t.any,
  });
};
export const postReclamations = (
  user: ReportRequestBody,
  cancelToken?: axios.CancelToken
) => {
  return axios.post('/evax/api/reclamations', user, {
    cancelToken,
    decoder: t.any,
  });
};

export const updateReclamations = (
  updateAccount: ReportRequestBody,
  cancelToken?: axios.CancelToken
) => {
  return axios.put(`/evax/api/reclamations`, updateAccount, {
    cancelToken,
    decoder: t.any,
  });
};

export const postSideEffects = (
  user: ReportRequestBody,
  cancelToken?: axios.CancelToken
) => {
  return axios.post('/evax/api/pharmaco', user, {
    cancelToken,
    decoder: t.any,
  });
};

export const updateSideEffects = (
  updateAccount: ReportRequestBody,
  cancelToken?: axios.CancelToken
) =>
axios.put(`/evax/api/pharmaco`, updateAccount, {
  cancelToken,
  decoder: t.any,
});

export const getCertif = (
  lg: 'FR' | 'EN' | 'AR',
  cancelToken?: axios.CancelToken
) =>
axios.get(`/evax/api/certif/qr`, {
  params: { lg },
  cancelToken,
  decoder: Certif,
});

export const getGrippeCertif = (
  lg: 'FR' | 'EN' | 'AR',
  cancelToken?: axios.CancelToken
) =>
axios.get(`/evax/api/certif-grippe/qr`, {
  params: { lg },
  cancelToken,
  decoder: Certif,
});


export const verifyGrippeCertif = (
  lg: 'FR' | 'EN' | 'AR',
  codeInscription,
  cancelToken?: axios.CancelToken
) =>
axios.get(`/evax/verify/qr-grippe/${codeInscription}`, {
  params: { lg },
  cancelToken,
  decoder: Certif,
});


const VaccineType = t.type({ nomVaccin: t.string, numVaccin: t.number });

export const getVaccineTypes = () =>
axios.get(`/evax/api/vaccines`, {
  decoder: t.array(VaccineType),
});
export const getVaccineGrippeTypes = () =>
axios.get(`/evax/api/vaccins_grippe`, {
  decoder: t.array(VaccineType),
});
