import * as React from 'react';
import { Button, Modal } from 'antd';
import { toPng } from 'html-to-image';
import { FormattedMessage } from 'react-intl';

type Props = {
  children?: React.ReactNode;
  imageRef: React.RefObject<HTMLDivElement>;
  disabled?: boolean;
  icon?: React.ReactNode;
  loading?: boolean;
};

const DownloadAsImage = ({ children, imageRef, ...rest }: Props) => (
  <Button
    {...rest}
    type="text"
    onClick={() => {
      if (!!imageRef.current) {
        toPng(imageRef.current, { backgroundColor: '#fff' }).then((dataURL) => {
          const element = document.createElement('a');
          element.href = dataURL;
          element.download = 'certif.png';
          element.click();
        });
      }
    }}
  >
    <div
      style={{
        display: 'inline-block',
        height: '0px',
        width: '0px',
        overflow: 'hidden',
      }}
    >
      {children}
    </div>
    Image
  </Button>
);
export default DownloadAsImage;
