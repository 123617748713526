// @ts-nocheck
import { FormOutlined } from '@ant-design/icons';
import { Button, Modal, Result, Steps, Typography } from 'antd';
import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';

import { postReclamationAction } from '../../shared/state/rapports';
import { State } from '../../shared/state/store';
import { loadable } from '../../shared/types';
import { TranslatedMessage } from '../../translations/data';
import PersonalDataComplaint from './PersonalDataComplaint';
import SelectDose from './SelectDose';
import SelectPassType from './SelectPassType';
import SelectReportCategory from './SelectReportCategory';
import VaccinationComplaint from './VaccinationComplaint';

const mapStateToProps = ({}: State) => ({});

const mapDispatchToProps = {
  postReclamation: postReclamationAction,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

type ReportValues =
  | {
      step: 0;
      category?: 'PersonalData' | 'Vaccination' | 'Pass';
    }
  | {
      step: 1;
      category: 'PersonalData';
      type?: number;
      contenu?: string;
    }
  | {
      step: 1;
      category: 'Vaccination';
      dose?: 1 | 2 | 3 | 4;
    }
  | {
      step: 1;
      category: 'Pass';
      type?: 'Activation' | 'Conversion';
    }
  | {
      step: 2;
      category: 'Vaccination';
      dose: 1 | 2 | 3 | 4;
      type?: number;
      contenu?: string;
    }
  | {
      step: 2;
      category: 'Pass';
      type: 'Activation' | 'Conversion';
    };

const Padded = styled.div`
  padding: 16px;
  text-align: center;
`;

const Reclamation = ({ postReclamation }: Props) => {
  const [values, setValues] = React.useState<ReportValues>({ step: 0 });
  const [isReclamationVisible, setIsReclamationVisible] = React.useState(false);
  const [lastActive, setLastActive] = React.useState(false);
  const loading = useSelector((s: State) =>
    loadable.isLoading(s.reports.postrecResult)
  );
  const isOk = useSelector((s: State) =>
    loadable.isOk(s.reports.postrecResult)
  );

  React.useEffect(() => {
    setValues({ step: 0 });
    setLastActive(
      Number(localStorage.getItem('lastReclamationPost')) + 300000 < Date.now()
    );
  }, [isReclamationVisible]);

  React.useEffect(() => {
    setIsReclamationVisible(false);
  }, [isOk]);

  return (
    <>
      <Button block onClick={() => setIsReclamationVisible(true)}>
        <TranslatedMessage id="reclamation" />
      </Button>
      <Modal
        title={
          <Typography.Title level={5}>
            <FormOutlined /> <TranslatedMessage id="reclamation" />
          </Typography.Title>
        }
        onCancel={() => setIsReclamationVisible(false)}
        cancelText={<TranslatedMessage id="Cancel" />}
        okText={
          values.step === 1 && values.category === 'PersonalData' ? (
            <TranslatedMessage id="Submit" />
          ) : (
            <TranslatedMessage id="next" />
          )
        }
        okButtonProps={{
          disabled:
            (values.step === 0 && !values.category) ||
            (values.step === 1 &&
              values.category === 'PersonalData' &&
              (!values.type || !values.contenu)) ||
            (values.step === 1 &&
              values.category === 'Vaccination' &&
              !values.dose) ||
            (values.step === 1 && values.category === 'Pass' && !values.type) ||
            (values.step === 2 &&
              values.category === 'Vaccination' &&
              (!values.type || !values.contenu)) ||
            !lastActive,
          loading: loading,
        }}
        onOk={() => {
          setValues((p) =>
            p.step === 0 && p.category
              ? { category: p.category, step: 1 }
              : p.step === 1 && p.category === 'Vaccination' && !!p.dose
              ? { step: 2, category: 'Vaccination', dose: p.dose }
              : p.step === 1 && p.category === 'Pass' && !!p.type
              ? { step: 2, category: 'Pass', type: p.type }
              : p
          );
          if (values.step === 2 && values.category === 'Pass') {
            setIsReclamationVisible(false);
          } else if (
            ((values.step === 2 && values.category === 'Vaccination') ||
              (values.step === 1 && values.category === 'PersonalData')) &&
            !!values.type &&
            !!values.contenu
          ) {
            postReclamation({
              type: values.type,
              contenu: values.contenu,
            });
          }
        }}
        visible={isReclamationVisible}
        destroyOnClose
      >
        {!lastActive ? (
          <Result
            status="warning"
            title={
              <TranslatedMessage
                id="actionwarn"
                values={{
                  number: Math.round(
                    (Number(localStorage.getItem('lastReclamationPost')) +
                      300000 -
                      Date.now()) /
                      1000 /
                      60
                  ),
                }}
              />
            }
          />
        ) : (
          <>
            <Steps current={values.step}>
              <Steps.Step />
              <Steps.Step />
              {(values.category === 'Vaccination' ||
                values.category === 'Pass') && <Steps.Step />}
            </Steps>
            {values.step === 0 && (
              <SelectReportCategory
                value={values.category}
                onChange={(category) => setValues({ step: 0, category })}
              />
            )}
            {values.step === 1 &&
              (values.category === 'PersonalData' ? (
                <PersonalDataComplaint
                  value={values}
                  onChange={(v) =>
                    setValues({ step: 1, category: 'PersonalData', ...v })
                  }
                />
              ) : values.category === 'Vaccination' ? (
                <SelectDose
                  value={values.dose}
                  onChange={(v) =>
                    setValues({ step: 1, category: 'Vaccination', dose: v })
                  }
                />
              ) : (
                <SelectPassType
                  onChange={(type) =>
                    setValues({
                      step: 1,
                      category: 'Pass',
                      type,
                    })
                  }
                />
              ))}
            {values.step === 2 &&
              (values.category === 'Vaccination' ? (
                <VaccinationComplaint
                  dose={values.dose}
                  value={values}
                  onChange={(v) =>
                    setValues({
                      step: 2,
                      category: 'Vaccination',
                      dose: values.dose,
                      ...v,
                    })
                  }
                />
              ) : (
                <Padded>
                  <p>
                    {values.type === 'Activation' ? (
                      <TranslatedMessage id="activationMessage" />
                    ) : (
                      <TranslatedMessage
                        id="convertMessage"
                        values={{
                          a: (msg: string) => (
                            <a href="http://conversion-pass.evax.tn/">{msg}</a>
                          ),
                          br: <br />,
                        }}
                      />
                    )}
                  </p>
                </Padded>
              ))}
          </>
        )}
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Reclamation);
