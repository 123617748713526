import { Loadable, LoadableC, loadable, NotRequested } from './Loadable'
import * as t from 'io-ts'
import { eq, monad, functor, monoid } from 'fp-ts'
import { pipeable, pipe } from 'fp-ts/lib/pipeable'
import { Ok } from './Result'

const URI = 'Memoed'
type URI = typeof URI

export type Memoed<A> = {
  _URI: URI
  v: Loadable<A>
  memo: A
}

export const MemoedC = <C extends t.Mixed>(c: C) =>
  t.type(
    {
      _URI: t.literal(URI),
      v: LoadableC(c),
      memo: c,
    },
    'Memoed'
  )

export const getEq = <A>(E: eq.Eq<A>): eq.Eq<Memoed<A>> => {
  const lEq = loadable.getEq(E)
  return {
    equals: (a, b) => E.equals(a.memo, b.memo) && lEq.equals(a.v, b.v),
  }
}

const getMonoid = <A>(M: monoid.Monoid<A>): monoid.Monoid<Memoed<A>> => {
  const lM = loadable.getMonoid(M)
  return {
    empty: init(M.empty),
    concat: (a, b) => from(M.concat(a.memo, b.memo))(lM.concat(a.v, b.v)),
  }
}

const extract = <A>(a: Memoed<A>): A => loadable.getOrElse(() => a.memo)(a.v)

const ap = <A, B>(fab: Memoed<(a: A) => B>, fa: Memoed<A>): Memoed<B> => ({
  _URI: URI,
  v: loadable.ap(fa.v)(fab.v),
  memo: fab.memo(fa.memo),
})

const map = <A, B>(fa: Memoed<A>, fab: (a: A) => B): Memoed<B> => ({
  _URI: URI,
  v: loadable.map(fab)(fa.v),
  memo: fab(fa.memo),
})

const of = <T>(a: T): Memoed<T> => ({
  _URI: URI,
  memo: a,
  v: Ok(a),
})

const init = <T>(a: T): Memoed<T> => ({ _URI: URI, memo: a, v: NotRequested })

const from = <T>(d: T) => (a: Loadable<T>): Memoed<T> => step(a)(init(d))

const chain = <A, B>(fa: Memoed<A>, fab: (a: A) => Memoed<B>): Memoed<B> => ({
  _URI: URI,
  v: pipe(
    fa.v,
    loadable.chain(l => fab(l).v)
  ),
  memo: fab(extract(fa)).memo,
})

const step = <A>(next: Loadable<A>) => (fa: Memoed<A>): Memoed<A> => {
  const memo = pipe(
    next,
    loadable.getOrElse(() => fa.memo)
  )
  return { memo, _URI: URI, v: next }
}

declare module 'fp-ts/lib/HKT' {
  interface URItoKind<A> {
    Memoed: Memoed<A>
  }
}

const memoedM: monad.Monad1<URI> & functor.Functor1<URI> = {
  URI,
  of,
  ap,
  map,
  chain,
}

export const memoed = {
  ...pipeable(memoedM),
  of,
  extract,
  init,
  step,
  from,
  getMonoid,
  memoed: memoedM,
}
