import * as t from 'io-ts';

import * as axios from '../../axios';
import defaultAxios from 'axios';
import { AccountInfo, Del, Gov, Login, PhoneChange } from './types';
import { Appointments } from '../../types/vaccin.type';

export const preAuth = (inscription: string, cancelToken?: axios.CancelToken) =>
  axios.post(
    `/evax/api/pre-auth?inscription=${inscription}`,
    {},
    {
      cancelToken,
      decoder: t.any,
    }
  );

export const preChangePhoneNumber = (
  phoneNumber: string,
  cancelToken?: axios.CancelToken
) =>
  axios.post(
    `/evax/api/citoyens/me/phonenumber?phoneNumber=${phoneNumber}`,
    {},
    {
      cancelToken,
      decoder: t.any,
    }
  );

export const confirmPhoneNumber = (
  data: PhoneChange,
  cancelToken?: axios.CancelToken
) =>
  axios.put(
    `/evax/api/citoyens/me/phonenumber/confirm?phoneNumber=${data.phoneNumber}&otp=${data.otp}`,
    data,
    {
      cancelToken,
      decoder: t.unknown,
    }
  );

export const cancelInscription = (
  reason: string,
  cancelToken?: axios.CancelToken
) =>
  axios.put(
    `/evax/api/citoyens/me/cancel_inscription`,
    {
      status: reason,
    },
    {
      cancelToken,
      decoder: t.unknown,
    }
  );

export const signIn = (user: Login, cancelToken?: axios.CancelToken) =>
  axios.post('/evax/api/login', user, {
    cancelToken,
    decoder: t.unknown,
  });

export const getDelegations = (id: string, cancelToken?: axios.CancelToken) =>
  axios.get(`/evax/api/delegations/${id}`, {
    cancelToken,
    decoder: t.array(Del),
  });

export const getAccount = (cancelToken?: axios.CancelToken) =>
  axios.get(`/evax/api/citoyens/me`, { cancelToken, decoder: AccountInfo });

export const getVac = (cancelToken?: axios.CancelToken) =>
  axios.get(`/evax/api/dossier/me`, { cancelToken, decoder: t.any });

export const getGouvernorats = (cancelToken?: axios.CancelToken) =>
  axios.get(`/evax/api/gouvernorats`, {
    cancelToken,
    decoder: t.array(Gov),
  });

export const getSexe = (cancelToken?: axios.CancelToken) =>
  axios.get(`/evax/api/sexes`, { cancelToken, decoder: t.array(t.any) });

export const changeRDV = () => defaultAxios.get(`/evax/api/cancelrdv`);

export const updateAccount = (
  updateAccount: any,
  cancelToken?: axios.CancelToken
) =>
  axios.put(`/evax/api/citoyens/me`, updateAccount, {
    cancelToken,
    decoder: t.any,
  });

export const logout = (cancelToken?: axios.CancelToken) =>
  axios.post('/auth/logout', {}, { cancelToken, decoder: t.any });

export const getBalance = (cancelToken?: axios.CancelToken) =>
  axios.get(`/evax/api/evax/vaccins`, {
    cancelToken,
    decoder: Appointments,
  });
  export const getBalanceGrippe = (cancelToken?: axios.CancelToken) =>
  axios.get(`/evax/api/evax/vaccins_grippe`, {
    cancelToken,
    decoder: Appointments,
  });
export const postLanguage = (lang: string, cancelToken?: axios.CancelToken) =>
  axios.put(
    `/evax/api/citoyens/me/language`,
    { language: lang },
    { cancelToken, decoder: t.any }
  );
