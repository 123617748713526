import * as t from 'io-ts'
import { Result, Err, Ok, ResultC } from './Result'
import { Either } from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/pipeable'
import { either as E } from 'fp-ts'

export type Action<T extends string> = { type: T }
export const Action = <C extends t.StringC>(codec: C) => t.interface({ type: codec })

export type PayloadAction<T extends string, P> = { type: T; payload: P }
export const PayloadAction = <C extends t.StringC, P extends t.Mixed>(typeCodec: C, payloadCodec: P) =>
  t.interface({ type: typeCodec, payload: payloadCodec })

export type FailableAction<T extends string, P> = PayloadAction<T, Result<P>>
export const FailableAction = <C extends t.StringC, P extends t.Mixed>(typeCodec: C, payloadCodec: P) =>
  PayloadAction(typeCodec, ResultC(payloadCodec))

export type ActionType<A extends Action<string>> = A['type']

export type ActionPayload<A extends PayloadAction<string, {}>> = A extends FailableAction<string, infer P>
  ? P
  : A extends PayloadAction<string, infer P>
  ? P
  : never

export const actionCreator = <A extends Action<string>>(type: ActionType<A>) => () => ({ type })

export const payloadActionCreator = <A extends PayloadAction<string, any>>(type: ActionType<A>) => (
  payload: ActionPayload<A>
) => ({ type, payload })

export const failableActionCreator = <A extends FailableAction<string, any>>(type: ActionType<A>) => ({
  ok: (payload: ActionPayload<A>): FailableAction<ActionType<A>, ActionPayload<A>> => ({
    type,
    payload: Ok(payload),
  }),
  err: (err: unknown): FailableAction<ActionType<A>, ActionPayload<A>> => ({
    type,
    payload: Err(err),
  }),
})

export const foldEitherToAction = <P, A extends FailableAction<string, P>>(actionCreator: {
  ok: (_: P) => A
  err: (_: unknown) => A
}) => (e: Either<unknown, P>): A => pipe(e, E.fold(actionCreator.err, actionCreator.ok))
