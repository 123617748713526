import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { pipe } from "fp-ts/lib/function";
import * as React from "react";
import { connect } from "react-redux";
import { AccountInfo, emptyAccountInfo } from "../../shared/state/Login";
import { State } from "../../shared/state/store";
import { loadable } from "../../shared/types";
import { TranslatedMessage } from "../../translations/data";
import CancelModal from "./CancelModal";

const mapStateToProps = ({ auth: { account } }: State) => ({
  accountInfo: pipe(
    account,
    loadable.getOrElse((): AccountInfo => emptyAccountInfo)
  ),
});

type Props = ReturnType<typeof mapStateToProps>;

const CancelInscription = ({ accountInfo }: Props) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <CancelModal visible={visible} close={() => setVisible(false)} />
      <Button
        disabled={!accountInfo.canCancelInscription}
        icon={<DeleteOutlined />}
        style={{ color: "#d80000" }}
        onClick={() => setVisible(true)}
      >
        <TranslatedMessage id="AnnulationDeInscreption" />
      </Button>
    </>
  );
};

export default connect(mapStateToProps)(CancelInscription);
