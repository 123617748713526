import { Select } from 'antd';
import styled from 'styled-components';
import { TranslatedMessage } from '../../translations/data';

type Category = 'PersonalData' | 'Vaccination' | 'Pass';

type ComplaintType = 'Activation' | 'Conversion';

type Props = {
  value?: ComplaintType;
  onChange?: (_?: ComplaintType) => void;
};

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  & > .ant-select {
    width: 80%;
  }
  padding: 16px;
`;

const SelectPassType = ({ value, onChange }: Props) => (
  <Centered>
    <TranslatedMessage id="selectPassTypePrompt" />
    <Select<ComplaintType> value={value} onChange={onChange}>
      <Select.Option value="Activation">
        <TranslatedMessage id="Activation" />
      </Select.Option>
      <Select.Option value="Conversion">
        <TranslatedMessage id="Conversion" />
      </Select.Option>
    </Select>
  </Centered>
);

export default SelectPassType;
