
import { SnippetsFilled } from '@ant-design/icons';
import { Alert, Button, Dropdown, Menu, Space } from 'antd';
import React, { useCallback, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Certif } from '../shared/state/rapports';

import { TranslatedMessage } from '../translations/data';
import CertificateGrippeInfo from './CertificateGrippeInfo';
import { useAccount, useGrippeCertificate } from './Home/queries';

const usePrintable = (lg: 'AR' | 'FR' | 'EN', enabled: boolean) => {
  const [certificate, setCertificate] = React.useState<Certif>();
  const ref = useRef(null);
  const onBeforeGetContentResolve = useRef<(() => void) | null>(null);
  const [isPrinting, setIsPrinting] = React.useState(false);

  const print = useReactToPrint({
      content: () => ref.current,
      onBeforeGetContent: () => {
        return new Promise((resolve) => {
          setIsPrinting(true);
          //@ts-ignore
          onBeforeGetContentResolve.current = resolve;
        });
      },
      onAfterPrint: () => setIsPrinting(false),
  });
  
  const callbackRef = useCallback((node) => {
      if (node !== null && onBeforeGetContentResolve.current !== null) {
        ref.current = node;
        onBeforeGetContentResolve.current();
      }
  }, [  ]);
  const query = useGrippeCertificate(lg, {
    enabled,
    onSuccess: (c) => setCertificate(c),
  });
  const account = useAccount();

  React.useEffect(() => {
    if (!!certificate) {
      print?.();
    }
  }, [!!certificate]);

  return {
    query,
    callbackRef,
    print,
    element: !!certificate && !!account && (
      isPrinting &&
     <React.Suspense fallback={null}> 
         <CertificateGrippeInfo
        ref={callbackRef}
        certificate={certificate}
        accountInfo={account}
        locale={lg === 'AR' ? 'ar-TN' : lg === 'FR' ? 'fr' : 'en'}
      />
      </React.Suspense>
     
    ),
  };
};

const VaccineGrippeCertificateDownload = ({ firstvacc = false }) => {
  const [enabledLangs, setEnabledLangs] = React.useState({
    ar: false,
    fr: false,
    en: false,
  });
  const printables = {
    ar: usePrintable('AR', enabledLangs.ar),
    fr: usePrintable('FR', enabledLangs.fr),
    en: usePrintable('EN', enabledLangs.en),
  };
  const allFailed = Object.values(printables).every((p) => p.query.isError);

  return (
    <Space direction="vertical" align="center">
      <div style={{ display: 'none' }}>
        {Object.entries(printables).map(([k, p]) => (
          <React.Fragment key={k}>{p.element}</React.Fragment>
        ))}
      </div>
      <Dropdown
        disabled={allFailed}
        overlay={
          <Menu
            onClick={(e) => {
              printables[e.key]?.print?.();
              setEnabledLangs((ls) => ({
                ...ls,
                [e.key]: true,
              }));
            }}
          items=
            {Object.entries(printables).map(([k, p]) => (
              { key:k,
                disabled:p.query.isError,
                label:<TranslatedMessage
                  id={k === 'ar' || k === 'fr' || k === 'en' ? k : 'ar'}
                />}
            ))}
          
          />
        
        }
      >
        <Button
          loading={Object.values(printables).some((p) => p.query.isLoading)}
          disabled={allFailed}
          icon={<SnippetsFilled />}
        >
          <TranslatedMessage id={firstvacc ? 'vacc1Certif' : 'certifButton'} />
        </Button>
      </Dropdown>
      {allFailed && (
        <Alert
          message={<TranslatedMessage id="certifVaccinaleNonDisponible" />}
          type="error"
        />
      )}
    </Space>
  );
};

export default VaccineGrippeCertificateDownload;
