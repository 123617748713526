import * as React from 'react';
import {
  Col,
  ConfigProvider,
  Descriptions,
  Divider,
  Image,
  Row,
  Typography,
} from 'antd';
import { HealthPass } from '../shared/types/vaccin.type';
import styled from 'styled-components';
import { useCodeInscription } from '../hooks';
import CertificateHeader from './CertificateHeader';

type Props = {
  healthPass: HealthPass;
};

const DescriptionEnglish = ({ healthPass }: { healthPass: HealthPass }) => (
  <Descriptions column={1}>
    <Descriptions.Item label="Full Name" labelStyle={{ fontWeight: 'bold' }}>
      <Typography.Text ellipsis>{healthPass?.fullNameEN}</Typography.Text>
    </Descriptions.Item>
    <Descriptions.Item
      label="Date of Birth"
      labelStyle={{ fontWeight: 'bold' }}
    >
      <Typography.Text ellipsis>{healthPass?.dateOfBirth}</Typography.Text>
    </Descriptions.Item>
    <Descriptions.Item
      label="Guardian Full Name (for children)"
      labelStyle={{ fontWeight: 'bold' }}
    >
      <Typography.Text ellipsis>
        {healthPass?.sponsoredFullNameEN}
      </Typography.Text>
    </Descriptions.Item>
    <Descriptions.Item
      label="Effective Date"
      labelStyle={{ fontWeight: 'bold' }}
    >
      <Typography.Text ellipsis>{healthPass?.effectiveDate}</Typography.Text>
    </Descriptions.Item>
  </Descriptions>
);

const DescriptionArabic = ({ healthPass }: { healthPass: HealthPass }) => (
  <ConfigProvider direction="rtl">
    <Descriptions column={1}>
      <Descriptions.Item
        label="الإسم واللقب"
        labelStyle={{ fontWeight: 'bold' }}
      >
        <Typography.Text ellipsis>{healthPass?.fullNameAR}</Typography.Text>
      </Descriptions.Item>
      <Descriptions.Item
        label="تاريخ الولادة"
        labelStyle={{ fontWeight: 'bold' }}
      >
        <Typography.Text ellipsis>{healthPass?.dateOfBirth}</Typography.Text>
      </Descriptions.Item>
      <Descriptions.Item
        label="إسم و لقب الولي (بالنسبة للقصر)"
        labelStyle={{ fontWeight: 'bold' }}
      >
        <Typography.Text ellipsis>
          {healthPass?.sponsoredFullNameAR}
        </Typography.Text>
      </Descriptions.Item>
      <Descriptions.Item
        label="تاريخ النفاذ"
        labelStyle={{ fontWeight: 'bold' }}
      >
        <Typography.Text ellipsis>{healthPass?.effectiveDate}</Typography.Text>
      </Descriptions.Item>
    </Descriptions>
  </ConfigProvider>
);

const SubHeaderRow = styled.div`
  text-align: center;
`;

const SubHeader = () => {
  const codeInscription = useCodeInscription();

  return (
    <SubHeaderRow>
      <Typography.Title level={3}>
        19- جواز التلقيح كوفيد
        <br />
        VACCINE PASSPORT COVID-19
      </Typography.Title>
      <Typography.Paragraph strong>
        Pass Id: {codeInscription}
      </Typography.Paragraph>
    </SubHeaderRow>
  );
};

class VaccinePassportInfo extends React.PureComponent<Props> {
  render() {
    const { healthPass } = this.props;

    return (
      <ConfigProvider direction="ltr">
        <Row justify="center" id="my-drawer">
          <Col span={20}>
            <CertificateHeader />
            <SubHeader />
            <Divider />
            <Row>
              <Col span={12}>
                <DescriptionEnglish healthPass={healthPass} />
              </Col>
              <Col span={12}>
                <DescriptionArabic healthPass={healthPass} />
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col span={12}>
                <Typography.Text ellipsis strong>
                  Electronic stamp:
                </Typography.Text>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Typography.Text ellipsis strong>
                  : الختم المرئي
                </Typography.Text>
              </Col>
            </Row>
            <Row justify="center" style={{ width: '100%' }}>
              <Col style={{ width: '40%' }}>
                {!!healthPass.qrData && (
                  <Image
                    width="100%"
                    src={`data:image/png;base64,${healthPass.qrData}`}
                  />
                )}
              </Col>
            </Row>
            <Divider />
            <Col>
              <ConfigProvider direction="rtl">
                <Row justify="start">
                  <Typography.Paragraph>
                    يتعين الاستظهار بوثيقة هوية تحمل صورة عند تقديم جواز التلقيح
                    الخاص بك للتثبت
                    <br />
                    يجب على الأطفال غير الحاملين لوثيقة هوية أن يكونوا رفقة
                    الولي الشرعي
                  </Typography.Paragraph>
                </Row>
              </ConfigProvider>
              <Row justify="start">
                <Typography.Paragraph>
                  A photo id must be available when presenting your vaccine pass
                  for verification
                  <br />
                  Children without id card must be accompanied by a legal
                  guardian
                </Typography.Paragraph>
              </Row>
            </Col>
          </Col>
        </Row>
      </ConfigProvider>
    );
  }
}

export default VaccinePassportInfo;
