import { Button, Col, Drawer, PageHeader, Result, Row, Typography } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';

import { FaCommentMedicalIcon } from '../../shared/constants';
import { State } from '../../shared/state/store';
import { loadable } from '../../shared/types';
import { emptyAppointment } from '../../shared/types/vaccin.type';
import { TranslatedMessage } from '../../translations/data';
import { useAppointments } from '../Home/queries';
import SideEffectsForm from './SideEffectsForm';

const mapStateToProps = (s: State) => ({
  locale: s.locale,
  disabled: loadable.isErr(s.reports.declaration),
});

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;

const SideEffectsModal = ({ locale }: Props) => {
  const appointmentsQuery = useAppointments();
  const appointments = appointmentsQuery.isSuccess
    ? appointmentsQuery.data
    : emptyAppointment;
  const [visible, setVisible] = React.useState(false);
  const [lastactive, setlastactive] = React.useState(false);
  React.useEffect(() => {
    setlastactive(
      Number(localStorage.getItem('lastSideEffectPost')) + 300000 < Date.now()
    );
  }, [visible]);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const disabledLogic = () => {
    return (
      appointments?.etatDossier?.id === 3 || appointments?.etatDossier?.id === 5
    );
  };
  return (
    <>
      <Button disabled={!disabledLogic()} onClick={showDrawer}>
        <Row gutter={[4, 0]} align="middle">
          <Col>
            <FaCommentMedicalIcon />
          </Col>
          <Col>
            <TranslatedMessage id="sideEffectsTitle" />
          </Col>
        </Row>
      </Button>
      <Drawer
        destroyOnClose
        className="my-drawer"
        placement={locale === 'ar-TN' ? 'left' : 'right'}
        closable={true}
        onClose={onClose}
        visible={visible}
      >
        <PageHeader
          title={
            <Typography.Title level={5}>
              <Row gutter={[16, 16]} align="middle">
                <Col>
                  <FaCommentMedicalIcon />
                </Col>
                <Col>
                  <TranslatedMessage id="sideEffectsTitle" />
                </Col>
              </Row>
            </Typography.Title>
          }
        />
        {!lastactive ? (
          <Row style={{ height: '100%' }} align="middle" justify="center">
            <Result
              status="warning"
              title={
                <TranslatedMessage
                  id="actionwarn"
                  values={{
                    number: Math.round(
                      (Number(localStorage.getItem('lastSideEffectPost')) +
                        300000 -
                        Date.now()) /
                        1000 /
                        60
                    ),
                  }}
                />
              }
            />
          </Row>
        ) : (
          <SideEffectsForm disabled={!lastactive} setVisible={onClose} />
        )}
      </Drawer>
    </>
  );
};

export default connect(mapStateToProps)(SideEffectsModal);
