// @ts-nocheck
import * as React from 'react';
import { render } from 'react-dom';
import App from './App';
import store, { history } from './shared/state/store';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import reportWebVitals from './reportWebVitals';
render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
