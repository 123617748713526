import * as t from 'io-ts'
import { either as E } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'

export const DateString = new t.Type<Date, string, unknown>(
  'DateString',
  (u): u is Date => u instanceof Date,
  (u, c) =>
    pipe(
      t.string.validate(u, c),
      E.map(s => new Date(s)),
      E.chain(d => (isNaN(d.getTime()) ? t.failure(u, c) : t.success(d)))
    ),
  a => a.toISOString()
)
