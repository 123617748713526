// @ts-nocheck
import './App.less';

import { QueryClient, QueryClientProvider } from 'react-query';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ConfigProvider } from 'antd';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Switch } from 'react-router';
import {
  createGlobalStyle,
  DefaultTheme,
  ThemeProvider,
} from 'styled-components';
import CertifLayout from './components/VerifyCertification/Layout';
import Authentication from './components/Authentication/Authentication';
import PrivateRoute from './components/Authentication/PrivateRoute';
import PublicRoute from './components/Authentication/PublicRoute';
import AppLayout from './shared/layout/Layout';
import Notifications from './shared/Notifications';
import { State } from './shared/state/store';
import data from './translations';
import VerifyGrippeCertification from './components/VerifyCertification/VerifyGrippeCertification';

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      bodyBackground: string;
      primary: string;
      primaryLight: string;
      primaryExtraLight: string;
      error: string;
      secondary: string;
      white: string;
      light: string;
      extraLight: string;
      extraDark: string;
      dark: string;
      darkLight: string;
      blue: string;
      red: string;
    };
    paddingUnit: number;

    footerHeight: number;
    headerHeight: number;
  }
}

const theme: DefaultTheme = {
  colors: {
    bodyBackground: '#f2f2f2',
    primary: '#d80000',
    primaryLight: 'rgba(0, 158, 15, 0.85)',
    primaryExtraLight: 'rgba(0, 158, 15, 0.25)',
    error: '#f44336',
    secondary: '#1da57a',
    white: '#fff',
    light: 'rgba(255, 255, 255, 0.65)',
    extraLight: 'rgba(255, 255, 255, 0.85)',
    extraDark: 'rgba(0, 0, 0, 0.85)',
    dark: 'rgba(0, 0, 0, 0.65)',
    darkLight: 'rgba(0, 0, 0, 0.25)',
    blue: '#2B78E4',
    red: '#CF2A27',
  },
  paddingUnit: 8,

  footerHeight: 64,
  headerHeight: 48,
};

const GlobalStyle = createGlobalStyle`
body {
  width: 100%;
  height: 100%;
  font-family: 'Helvetica', 'Arial', sans-serif;
   

}

#root {
  width: 100%;
  height: 100%;

}
.ant-descriptions-view {
    width: 100%;
    overflow: hidden;
    border-radius: initial;
}

*.ant-btn {
  & > *.anticon,
  & > *.ant-btn-loading-icon {
    margin-right: 8px;
   }

   & > *.anticon:last-child {
    margin-right: 0px;
   }
}

*.ant-btn.ant-btn-icon-only {
  & > *.anticon,
  & > *.ant-btn-loading-icon {
    margin-right: 0px;
  }
}

tr {
 & > th:first-child,
  &  > td:first-child {
   
  }

  & > th:last-child,
  & > td:last-child {
  
  }
}
`;

const formats = {
  time: {
    '12hour': { hour12: true, hours: 'numeric', minute: 'numeric' },
  },
};

const mapStateToProps = (s: State) => ({
  locale: s.locale,
});

type StateProps = ReturnType<typeof mapStateToProps>;

const queryClient = new QueryClient();

const App = ({ locale }) => (
  <QueryClientProvider client={queryClient}>
    <IntlProvider
    //@ts-ignore
      formats={formats}
      locale={locale}
      messages={data[locale] as Record<string, string>}
    >
      <ThemeProvider theme={theme}>
        <GoogleReCaptchaProvider
          reCaptchaKey="6Leb3OkbAAAAAENsh6wsDtJR17D9nAOe5C2ODZCG"
          language={locale === 'ar-TN' ? 'ar' : locale}
          scriptProps={{ async: true, defer: true, appendTo: 'body' }}
        >
          <ConfigProvider direction={locale === 'ar-TN' ? 'rtl' : 'ltr'}>
            <GlobalStyle />
            <Notifications />
            <Switch>
              <PublicRoute path="/verify/:ref" component={VerifyGrippeCertification} />
              <PublicRoute path="/auth" component={Authentication} />
              <PrivateRoute component={AppLayout} />
            </Switch>
          </ConfigProvider>
        </GoogleReCaptchaProvider>
      </ThemeProvider>
    </IntlProvider>
  </QueryClientProvider>
);

export default connect(mapStateToProps)(App);
