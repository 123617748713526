import { array, option } from 'fp-ts';
import { pipe } from 'fp-ts/lib/pipeable';

import { Locale } from './../../translations/data';
import { PayloadAction, payloadActionCreator } from './../types';

export type State = Locale;

export const initialState: State = pipe(
  [...navigator.languages],
  array.findFirst(Locale.is),
  option.getOrElse<Locale>(() => 'ar-TN')
);

export type SetLocaleAction = PayloadAction<'set_locale', Locale>;
export const setLocaleAction = payloadActionCreator<SetLocaleAction>('set_locale');

export type Actions = SetLocaleAction;

export const reducer = (s = initialState, a: Actions): State => {
  switch (a.type) {
    case 'set_locale':
      return a.payload;

    default:
      return s;
  }
};
