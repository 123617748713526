// @ts-ignore
import styled from 'styled-components';
import { TranslatedMessage } from '../../translations/data';
import { Select, Input, Modal, DatePicker } from 'antd';
import { useAppointments } from '../Home/queries';
import React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import VaccineTypeSelect from './VaccineTypeSelect';

const TypeSelect = styled.div`
  flex: 1;
  min-width: 136px;
  & > * {
    width: 100%;
  }
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  & > * {
    flex: 3;
  }
  & > ${TypeSelect} {
    flex: 1;
    min-width: 136px;
  }
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
  & > ${InputRow} {
    width: 100%;
  }
`;

type Props = {
  dose: 1 | 2 | 3 | 4;
  value?: { type?: number; contenu?: string };
  onChange?: (_: { type?: number; contenu?: string }) => void;
};

type Field = 'Date' | 'Type' | 'RDV';

const typeLookup = {
  1: { Date: 6, Type: 5, RDV: 116 },
  2: { Date: 7, Type: 16, RDV: 227 },
  3: { Date: 307, Type: 316, RDV: 337 },
  4: { Date: 407, Type: 416, RDV: 447 },
};

const VacinnationComplaint = ({ dose, value, onChange }: Props) => {
  const [field, setField] = React.useState<Field>();
  const appointmentsQuery = useAppointments();
  const alreadyVaccinated =
    appointmentsQuery.data?.appointments?.[dose - 1]?.status === 'DONE';
  const hasAppointment = !!appointmentsQuery.data?.appointments?.[dose - 1];
  const intl = useIntl();

  React.useEffect(() => {
    if (field === 'RDV' && (dose === 2 || dose === 3)) {
      Modal.info({
        content: intl.formatMessage(
          {
            id: !hasAppointment
              ? dose === 2
                ? 'dose2NoAppointmentWarning'
                : 'dose3NoAppointmentWarning'
              : 'dose2Or3HasAppointmentWarning',
          },
          { dose }
        ),
      });
    }
  }, [dose, field, hasAppointment]);

  return (
    <Centered>
      <TranslatedMessage id="personalDataComplaintPrompt" />
      <InputRow>
        <TypeSelect>
          <Select<Field>
            loading={appointmentsQuery.isLoading}
            value={field}
            onChange={(field) => {
              onChange?.({
                type: typeLookup[dose][field],
                contenu: value?.contenu,
              });
              setField(field);
            }}
          >
            <Select.Option value="Date" key="date">
              <TranslatedMessage id="date" />
            </Select.Option>
            <Select.Option value="Type" key="type">
              <TranslatedMessage id="type" />
            </Select.Option>
            {(!alreadyVaccinated || dose !== 1) && (
              <Select.Option value="RDV" key="rdv">
                <TranslatedMessage id="RendezVous" />
              </Select.Option>
            )}
          </Select>
        </TypeSelect>
        {field === 'Date' ? (
          //@ts-ignore
          <DatePicker
            value={!!value?.contenu ? moment(value.contenu) : undefined}
            onChange={(v) =>
              onChange?.({ type: value?.type, contenu: v?.toISOString() })
            }
          />
        ) : field === 'Type' ? (
          <VaccineTypeSelect
            onChange={(contenu) =>
              onChange?.({ type: value?.type, contenu: contenu?.toString() })
            }
          />
        ) : (
          <Input
            disabled={
              (dose === 2 || dose === 3) && !hasAppointment && field === 'RDV'
            }
            value={value?.contenu}
            onChange={(e) =>
              onChange?.({ type: value?.type, contenu: e.target.value })
            }
          />
        )}
      </InputRow>
    </Centered>
  );
};

export default VacinnationComplaint;
