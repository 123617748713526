import * as t from "io-ts";

import * as axios from "../../shared/axios";
import { DateString } from "../../shared/types";

const Relations = t.array(t.type({ id: t.number, type: t.string }));

export const getRelations = (cancelToken?: axios.CancelToken) =>
  axios.get(`/evax/api/citoyens/sponsorship`, {
    cancelToken,
    decoder: Relations,
  });

export const sendSponsoreeCode = (
  sponsoreeCode: string,
  cancelToken?: axios.CancelToken
) =>
  axios.post(
    `/evax/api/citoyens/children`,
    { sponsoreeCode },
    { cancelToken, decoder: t.unknown }
  );

type Sponsorship = {
  typeSponsoree: number;
  otp: string;
  sponsoreeCode: string;
  desc: string | undefined;
};

export const confirmSponsorship = (
  sponsorship: Sponsorship,
  cancelToken?: axios.CancelToken
) =>
  axios.post(`/evax/api/citoyens/children/verify`, sponsorship, {
    cancelToken,
    decoder: t.unknown,
  });

const Sponsoree = t.type({
  codeInscription: t.string,
  nomInscrit: t.string,
  prenomInscrit: t.string,
  dnInscrit: DateString,
});

export type Sponsoree = t.TypeOf<typeof Sponsoree>;

const Sponsorees = t.array(Sponsoree);

export const getSponsorees = (cancelToken?: axios.CancelToken) =>
  axios.get(`/evax/api/citoyens/children`, {
    cancelToken,
    decoder: Sponsorees,
  });
