import { Card } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import greenNumber from './../../assets/numero-vert.svg';

const GreenCard = styled(Card)`
  border-color: ${(props) => props.theme.colors.secondary};
  & > .ant-card-body {
    padding: 2px;
  }
`;
const GreenphoneCard = () => {
  return (
    <img
      src={greenNumber}
      alt="green number"
      style={{
        height: '2rem',
        width: '100%',
        /* even more control with max-width */
        maxWidth: '300px',
      }}
    />
  );
};

export default GreenphoneCard;
