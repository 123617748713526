// @ts-ignore
import React from 'react';
import { Select } from 'antd';
import { useVaccineTypes } from './queries';

type Props = {
  value?: number;
  onChange?: (_?: number) => void;
};

const VaccineTypeSelect = ({ value, onChange }: Props) => {
  const vaccineTypesQuery = useVaccineTypes();

  return (
    <Select<number>
      value={value}
      loading={vaccineTypesQuery.isLoading}
      onChange={onChange}
    >
      {vaccineTypesQuery.data?.map((t) => (
        <Select.Option key={t.numVaccin} value={t.numVaccin}>
          {t.nomVaccin}
        </Select.Option>
      ))}
    </Select>
  );
};

export default VaccineTypeSelect;
