import * as React from 'react';
import { CalendarFilled } from '@ant-design/icons';
import { array, option } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import { Result, Spin, Typography } from 'antd';
import { FormattedTime, useIntl } from 'react-intl';
import { TranslatedMessage } from '../../translations/data';
import styled from 'styled-components';
import { useAppointments } from './queries';

const AppointmentTime = styled.div`
  font-size: 2em;
`;

const AppointmentBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const CalendarIcon = ({ date }: { date: Date }) => {
  const intl = useIntl();

  return (
    <time
      dateTime={intl.formatDate(date, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })}
      className="icon"
    >
      <em>
        {intl.formatDate(date, {
          weekday: 'long',
        })}
      </em>
      <strong>
        {intl.formatDate(date, {
          month: 'long',
        })}
      </strong>
      <span>
        {intl.formatDate(date, {
          day: 'numeric',
        })}
      </span>
    </time>
  );
};

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const AppointmentOverview = () => {
  const appointmentsQuery = useAppointments();

  const currentAppointment = pipe(
    appointmentsQuery.isSuccess ? appointmentsQuery.data.appointments : [],
    array.filter((a) => a.status === 'PENDING'),
    array.head,
    option.toUndefined
  );

  return (
    <Spin spinning={appointmentsQuery.isLoading}>
      <CenterColumn>
        <Typography.Title level={5}>
          <CalendarFilled style={{ color: '#d80000' }} />{' '}
          <TranslatedMessage id="DateRDVTitle" />
        </Typography.Title>
        {!!currentAppointment ? (
          <AppointmentBlock>
            <CalendarIcon date={currentAppointment?.date ?? new Date(0)} />
            <AppointmentTime>
              <FormattedTime
                value={currentAppointment.date}
                hour="numeric"
                minute="numeric"
                format="12hour"
                timeZone="UTC"
              />
            </AppointmentTime>
          </AppointmentBlock>
        ) : (
          <Result
            subTitle={
              <Typography.Title level={5}>
            <TranslatedMessage id="noAppointment" />
            </Typography.Title>}
            status="warning"
          />
        )}
      </CenterColumn>
    </Spin>
  );
};

export default AppointmentOverview;
