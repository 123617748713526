import { SnippetsFilled } from '@ant-design/icons';
import { Alert, Button, Dropdown, Menu, Space } from 'antd';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Certif } from '../shared/state/rapports';

import { TranslatedMessage } from '../translations/data';
import CertificateInfo from './CertificateInfo';
import { useAccount, useCertificate } from './Home/queries';

const usePrintable = (lg: 'AR' | 'FR' | 'EN', enabled: boolean) => {
  const [certificate, setCertificate] = React.useState<Certif>();
  const ref = useRef(null);
  const print = useReactToPrint({ content: () => ref.current });
  const query = useCertificate(lg, {
    enabled,
    onSuccess: (c) => setCertificate(c),
  });
  const account = useAccount();

  React.useEffect(() => {
    if (!!certificate) {
      print?.();
    }
  }, [!!certificate]);

  return {
    query,
    ref,
    print,
    element: !!certificate && !!account && (
      <CertificateInfo
        ref={ref}
        certificate={certificate}
        accountInfo={account}
        locale={lg === 'AR' ? 'ar-TN' : lg === 'FR' ? 'fr' : 'en'}
      />
    ),
  };
};

const VaccineCertificateDownload = ({ firstvacc = false }) => {
  const [enabledLangs, setEnabledLangs] = React.useState({
    ar: false,
    fr: false,
    en: false,
  });
  const printables = {
    ar: usePrintable('AR', enabledLangs.ar),
    fr: usePrintable('FR', enabledLangs.fr),
    en: usePrintable('EN', enabledLangs.en),
  };
  const allFailed = Object.values(printables).every((p) => p.query.isError);

  return (
    <Space direction="vertical" align="center">
      <div style={{ display: 'none' }}>
        {Object.entries(printables).map(([k, p]) => (
          <React.Fragment key={k}>{p.element}</React.Fragment>
        ))}
      </div>
      <Dropdown
        disabled={allFailed}
        overlay={
          <Menu
            onClick={(e) => {
              printables[e.key]?.print?.();
              setEnabledLangs((ls) => ({
                ...ls,
                [e.key]: true,
              }));
            }}
          >
            {Object.entries(printables).map(([k, p]) => (
              <Menu.Item key={k} disabled={p.query.isError}>
                <TranslatedMessage
                  id={k === 'ar' || k === 'fr' || k === 'en' ? k : 'ar'}
                />
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Button
          loading={Object.values(printables).some((p) => p.query.isLoading)}
          disabled={allFailed}
          icon={<SnippetsFilled />}
        >
          <TranslatedMessage id={firstvacc ? 'vacc1Certif' : 'certifButton'} />
        </Button>
      </Dropdown>
      {allFailed && (
        <Alert
          message={<TranslatedMessage id="certifVaccinaleNonDisponible" />}
          type="error"
        />
      )}
    </Space>
  );
};

export default VaccineCertificateDownload;
