import * as React from 'react';
import moment from 'moment';
import {
  Col,
  ConfigProvider,
  Descriptions,
  Divider,
  Image as ImageAnt,
  Row,
  Typography,
} from 'antd';
import data, { Locale, TranslatedMessage } from '../translations/data';
import { Certif } from '../shared/state/rapports';
import { FormattedDate, FormattedMessage, IntlProvider } from 'react-intl';
import { AccountInfo } from '../shared/state/Login';
import { array, option } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import styled, { css } from 'styled-components';
import ministereSanteLogo from '../assets/ministere sante.jpg';
import { ReactComponent as Flag } from '../assets/flag.svg';
import { isCin } from '../shared/regex';

const Logo = styled.div<{ offset?: boolean }>`
  padding-top: 16px;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  ${(props) =>
    props.offset
      ? css`
          transform: translateY(-24px);
        `
      : ''}
  & > *:first-child {
    width: 100%;
    max-width: 100px;
    margin-bottom: 12px;
  }
`;

const Heading = styled(Typography.Title)<{ offset?: boolean }>`
  text-align: center;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  ${(props) => (props.offset ? 'transform: translateY(-24px);' : '')}
`;

const LogosDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0;
  margin-bottom: 16px;
`;

let thisYear :  number = new Date().getFullYear();
let nextYear :  number =  thisYear +1;

const CertificateHeader = () => (
  <LogosDiv>
    <Logo>
      <img src={ministereSanteLogo} />
      <Heading level={5}>
        <Typography id="ministryOfHealth" />
      </Heading>
    </Logo>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Heading level={3} style={{ textAlign: 'center' }}>
        <FormattedMessage id="certifGrippeTitle" />
      </Heading>
    </div>
    <Logo>
      <Flag />
      <Heading level={5}>
        <Typography id="republicTunisia" />
      </Heading>
      <Heading level={5}></Heading>
    </Logo>
  </LogosDiv>
);

type Props = {
  locale: Locale;
  certificate: Certif;
  accountInfo: AccountInfo;
} & React.RefAttributes<CertificateGrippeInfo>;

class CertificateGrippeInfo extends React.PureComponent<Props> {
  render() {
    const { locale, accountInfo, certificate: c } = this.props;
    const center = pipe(
      c.vaccineDTOS,
      array.map((x) => x.vaccinationCenter),
      array.filter((c) => !!c),
      array.head,
      option.toNullable
    );

    return (
      <IntlProvider
        locale={locale}
        messages={data[locale] as Record<string, string>}
      >
        <ConfigProvider direction={locale === 'ar-TN' ? 'rtl' : 'ltr'}>
          <Row justify="center" id="my-drawer">
            <Col style={{margin:'50px'}} span={30}>
              <CertificateHeader />
              <Divider style={{ margin: '24px 6px' }} />
              <Descriptions column={2}>
                <Descriptions.Item
                  span={2}
                  label={<TranslatedMessage id="NumeroInscription" />}
                  labelStyle={{ fontWeight: 'bold' }}
                >
                  <Typography.Text ellipsis>
                    {accountInfo?.codeInscription}
                  </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item
                  label={<TranslatedMessage id="NomComplet" />}
                  labelStyle={{ fontWeight: 'bold' }}
                >
                  <Typography.Text ellipsis>{`${c?.firstName ?? ''} ${
                    c?.lastName ?? ''
                  }`}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item
                  label={isCin.test(c?.idNumber) ? <TranslatedMessage id="NumeroCIN" /> : <TranslatedMessage id="Passport" /> }
                  labelStyle={{ fontWeight: 'bold' }}
                >
                  <Typography.Text ellipsis> {c?.idNumber}</Typography.Text>
                </Descriptions.Item>
                {c?.idType !== 'N/D' && (
                  <Descriptions.Item
                    span={2}
                    label={<TranslatedMessage id="TypeIdentifiant" />}
                    labelStyle={{ fontWeight: 'bold' }}
                  >
                    <Typography.Text ellipsis>
                      {' '}
                      {c?.idType ? c?.idType : 'CIN'}
                    </Typography.Text>
                  </Descriptions.Item>
                )}
                {c?.dateOfBirth !== 'N/D' && (
                  <Descriptions.Item
                    label={<TranslatedMessage id="birthday" />}
                    labelStyle={{ fontWeight: 'bold' }}
                  >
                    <Typography.Text ellipsis>
                      <FormattedDate
                        value={moment(c?.dateOfBirth).format()}
                        day="numeric"
                        month="long"
                        year="numeric"
                      />
                    </Typography.Text>
                  </Descriptions.Item>
                )}
              </Descriptions>
              <Divider style={{ margin: '24px 6px' }} />
              <Descriptions column={2}>
                {c?.vaccinationCertificateId !== 'N/D' && (
                  <Descriptions.Item span={30}>
                    <Row>
                      <Col span={15}>
                        <Typography.Text strong ellipsis={true}>
                          <TranslatedMessage id="refCertif" />
                        </Typography.Text>
                      </Col>
                      <Col span={20}>
                        <Typography.Text ellipsis={true}>
                          {' '}
                          {c?.vaccinationCertificateId}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Descriptions.Item>
                )}
                {(c?.vaccineDTOS ?? []).map((certif, i) => (
                  <React.Fragment key={i}>
                    <Descriptions.Item
                      span={1}
                      labelStyle={{ fontWeight: 'bold' }}
                      label={
                        <TranslatedMessage
                          id="VaccineName"
                          values={{ n: i + 1 }}
                        />
                      }
                    >
                      <Typography.Text ellipsis>
                        {certif.vaccineName}
                      </Typography.Text>
                    </Descriptions.Item>

                    <Descriptions.Item
                      span={1}
                      labelStyle={{ fontWeight: 'bold' }}
                      label={
                        <TranslatedMessage id="lotVacc" values={{ n: i + 1 }} />
                      }
                    >
                      <Typography.Text>
                        {certif.vaccinReference}
                      </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={2}
                      labelStyle={{ fontWeight: 'bold' }}
                      label={
                        <TranslatedMessage
                          id="DateRdvVac"
                          values={{ n: i + 1 }}
                        />
                      }
                    >
                      <FormattedDate
                        value={moment(certif.vaccinDate).format()}
                        day="numeric"
                        month="long"
                        year="numeric"
                      />
                    </Descriptions.Item>
                  </React.Fragment>
                ))}

                {c?.covidDate && (
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 'bold' }}
                    label={<FormattedMessage id="dateCovid" />}
                  >
                    <FormattedDate
                      value={moment(c?.covidDate).format()}
                      day="numeric"
                      month="long"
                      year="numeric"
                    />
                  </Descriptions.Item>
                )}
                {c?.covidDate && (
                  <Descriptions.Item>
                    <Typography.Text>
                      <FormattedMessage
                        id="hasCovidMessage"
                        defaultMessage="has got covid symptom on"
                      />
                    </Typography.Text>
                  </Descriptions.Item>
                )}

                {c?.dateCovid && (
                  <Descriptions.Item
                    label={<FormattedMessage id="dateCovid" />}
                    labelStyle={{ fontWeight: 'bold' }}
                  >
                    <FormattedDate
                      value={moment(accountInfo?.dateCovid).format()}
                      day="numeric"
                      month="long"
                      year="numeric"
                    />
                  </Descriptions.Item>
                )}

                {c?.hasCovid && (
                  <Descriptions.Item>
                    <Typography.Text>
                      <FormattedMessage
                        id="hasCovidMessage"
                        defaultMessage="has got covid symptom on"
                      />
                    </Typography.Text>
                  </Descriptions.Item>
                )}

                <Descriptions.Item
                  span={2}
                  labelStyle={{ fontWeight: 'bold' }}
                  label={<TranslatedMessage id="CenterDeVaccination" />}
                >
                  <Typography.Text>{center}</Typography.Text>
                </Descriptions.Item>
              </Descriptions>

              <div>
                <Typography.Text strong ellipsis>
                  <FormattedMessage id="QRCode" defaultMessage="" />
                </Typography.Text>
                <Row justify="center" style={{ width: '100%' }}>
                  <Col style={{ width: '30%' }}>
                    {!!c?.qrCode && (
                      <ImageAnt src={`data:image/png;base64,${c.qrCode}`} />
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </ConfigProvider>
      </IntlProvider>
    );
  }
}

export default CertificateGrippeInfo;
