import * as React from 'react';
import { Button, Card, Space, Typography } from 'antd';
import {ArrowLeftOutlined,ArrowRightOutlined, CaretRightOutlined,CaretLeftOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import { TranslatedMessage } from '../../translations/data';
import AppointmentOverview from './AppointmentOverview';
import Passes from './Passes';
import styled from 'styled-components';
import DoseDetails from './DoseDetails';
import { useAppointments,useGrippeAppointments } from './queries';
import SideEffectsModal from '../reports/SideEffectsModal';
import { State } from '../../shared/state/store';
import { loadable } from '../../shared/types';
import DoseGrippeDetails from './DoseGrippeDetails';
import DelegationForm from './DelegationForm';

const DossierLayout = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 2fr; 
`;

const EmptyFolderLayout = styled.div`
  display: block;
  height: auto;
`;
const HomeLayout = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: auto;`;     
const mapStateToProps = (s: State) => ({
  locale: s.locale,
  disabled: loadable.isErr(s.reports.declaration),
});

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;

const Dossier = ({ locale }: Props) => {
  const [mode, setMode] = React.useState<'grippe_details' | 'covid_details' | 'covid' | 'home' | 'grippe' >(
    'home'
  );
  const [detailType,setDetailType] = React.useState(); 
  const setNavigation = (m) =>{
    setMode(m);
    setDetailType(m);
  }
  const appointmentsQuery = useAppointments();
  const grippeAppointmentQuery = useGrippeAppointments();

  return (
    <Card
      title={
        mode === 'grippe_details' || mode === 'covid_details' ? (
          <Typography.Title level={4}>
          <TranslatedMessage id="vaccDetails" />
        </Typography.Title>
        ) :
       (mode === 'home' ? 
       <Typography.Title level={4}>
       <TranslatedMessage id="vacInfo" />
      </Typography.Title>: mode === 'covid' ?
       <Space size="large">
       {locale === 'ar-TN' ? <ArrowRightOutlined onClick={()=> setMode('home')}/> : <ArrowLeftOutlined onClick={()=> setMode('home')}/>} 
     <Typography.Title level={4}>
     <TranslatedMessage id="COVID-19" />
     </Typography.Title>
     </Space> :  <Space size="large">
       {locale === 'ar-TN' ? <ArrowRightOutlined onClick={()=> setMode('home')}/> : <ArrowLeftOutlined onClick={()=> setMode('home')}/>} 
     <Typography.Title level={4}>
     <TranslatedMessage id="GRIPPE" />
     </Typography.Title>
     </Space> )
      }
      extra={   
        mode === 'home' ? (<></>):
        detailType === 'covid' ?
        (<Space>
          <Button
            disabled={
              mode === 'covid' &&
              (appointmentsQuery.data?.count ?? 0) === 0
            }
            onClick={() =>
              setMode((m) => ( m === 'covid' ? 'covid_details' :'covid'))
            }
          >
            {mode === 'covid' ? (
              <TranslatedMessage id="vaccDetails" />
            ) : (
              <TranslatedMessage id="appointmentAndPass" />
            )}
          </Button>
          <SideEffectsModal />
        </Space>) : 
        (<Space>
          <Button
            disabled={
              mode === 'grippe' &&
              (grippeAppointmentQuery.data?.count ?? 0) === 0
            }
            onClick={() =>
              setMode((m) => ( m === 'grippe' ? 'grippe_details' : 'grippe'))
            }
          >
            {mode === 'grippe' ? (
              <TranslatedMessage id="vaccDetails" />
            ) : (
              <TranslatedMessage id="Medicaldoc_Grippe" />
            )}
          </Button>
        </Space>)
      }
    >
      {mode === 'covid' && appointmentsQuery.isError ? <EmptyFolderLayout>
      <DelegationForm/> 
      </EmptyFolderLayout> : mode === 'home' ? 
      <HomeLayout>
        <div style={{textAlign:'center'}}>
          <Button icon={locale === 'ar-TN' ? <CaretLeftOutlined />:<CaretRightOutlined />} block style={{backgroundColor:"#d80000",color:"#FFFFFF",width:"250px",height:'50px',justifyContent:'space-between',display:'flex',alignItems:'center',marginBottom:'5px'}} onClick={()=>setNavigation('covid')}><TranslatedMessage id="COVID-19"/> </Button>
         { (grippeAppointmentQuery.data?.count ?? 0) === 0 ?
          <div style={{cursor:'not-allowed'}}>
          <Button icon={locale === 'ar-TN' ? <CaretLeftOutlined />:<CaretRightOutlined />} block style={{backgroundColor:"grey",color:"#FFFFFF",width:"250px",height:'50px',justifyContent:'space-between',display:'flex',alignItems:'center',pointerEvents: 'none'
          }} onClick={()=>setNavigation('grippe')}><TranslatedMessage id="GRIPPE"/> </Button> </div> : 
          <Button icon={locale === 'ar-TN' ? <CaretLeftOutlined />:<CaretRightOutlined />} block style={{backgroundColor:"#d80000",color:"#FFFFFF",width:"250px",height:'50px',justifyContent:'space-between',display:'flex',alignItems:'center'}} onClick={()=>setNavigation('grippe')}><TranslatedMessage id="GRIPPE"/> </Button>
        }</div>
      </HomeLayout> 
       :  mode === 'grippe' ? <HomeLayout>
       <Passes vacinType={detailType}/>
       </HomeLayout>:
      <DossierLayout>
        { mode === 'covid' ? 
        ( 
          <>
           <AppointmentOverview />
            <Passes vacinType={detailType}/>
          </>
          )
          :
           mode ==='covid_details' ? 
          (<DoseDetails/>) : 
          (<DoseGrippeDetails/>)
          }
      </DossierLayout>
        }
    </Card>
  );
};

export default connect(mapStateToProps)(Dossier);
