import { either, task } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import { useQuery } from 'react-query';
import {
  getReclamations,
  getVaccineTypes,
  getVaccineGrippeTypes
} from '../../shared/state/rapports/api';
import { useAccount } from '../Home/queries';



export const useReclamations = () => {
  const account = useAccount();

  return useQuery(
    ['reclamation', account?.codeInscription],
    () =>
      pipe(
        getReclamations(),
        task.map(
          either.fold(
            (e) => {
              throw e;
            },
            (x) => x
          )
        )
      )(),
    { enabled: !!account }
  );
};

export const useVaccineTypes = () =>
  useQuery('vaccine-types', () =>
    pipe(
      getVaccineTypes(),
      task.map(
        either.fold(
          (e) => {
            throw e;
          },
          (x) => x
        )
      )
    )()
  );

  export const useVaccineGrippeTypes = () =>
  useQuery('vaccine-grippe-types', () =>
    pipe(
      getVaccineGrippeTypes(),
      task.map(
        either.fold(
          (e) => {
            throw e;
          },
          (x) => x
        )
      )
    )()
  );
