import { useDispatch, useSelector } from 'react-redux';
import {
  LoginOutlined,
  RightCircleOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Steps,
  Typography,
} from 'antd';
import { pipe } from 'fp-ts/lib/pipeable';
import * as React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { loginAction, preAuthAction } from '../../shared/state/Login';
import { loadable } from '../../shared/types';
import { TranslatedMessage } from '../../translations/data';
import ChangePhoneNumber from './ChangePhonenumber';
import { option } from 'fp-ts';

const LoginHeader = styled(Typography.Title)`
  margin: 30px auto;
  text-align: center;
`;

const LoginComponent = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const dispatch = useDispatch();
  const loginLoadable = useSelector((s) => s.auth.loginResult);
  const preAuthLoadable = useSelector((s) => s.auth.preAuthResult);
  const preAuth = pipe(preAuthLoadable, loadable.toOption, option.toUndefined);
  const preAuthError = loadable.isErr(preAuthLoadable);
  const loginError = loadable.isErr(loginLoadable);

  const [changeModalVisible, setChangeModalVisible] = React.useState(false);
  const [current, setCurrent] = React.useState(0);
  const [token, setToken] = React.useState<string>();

  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!!executeRecaptcha) {
      setToken(undefined);
      setToken(await executeRecaptcha());
    }
  }, [executeRecaptcha]);

  React.useEffect(() => {
    if (loadable.isOk(preAuthLoadable)) {
      handleReCaptchaVerify();
    }
  }, [
    !!executeRecaptcha,
    handleReCaptchaVerify,
    loginLoadable._tag,
    preAuthLoadable._tag,
  ]);

  const [retry, setRetry] = React.useState(false);
  const [inscriptionId, setInscriptionId] = React.useState('');
  const intl = useIntl();

  React.useEffect(() => {
    setRetry(false);
    if (loadable.isOk(preAuthLoadable)) {
      setTimeout(() => setRetry(true), 10000);
      setCurrent(1);
    }
  }, [loadable.isOk(preAuthLoadable)]);

  return (
    <Card>
      <LoginHeader level={4}>
        <TranslatedMessage id="LOGINTITLE" />
      </LoginHeader>
      <Row
        gutter={[16, 16]}
        justify="center"
        style={{ marginTop: 24, marginBottom: 24 }}
      >
        <Col lg={24} xs={24}>
          <Steps responsive={true} progressDot current={current}>
            <Steps.Step title={<TranslatedMessage id="NumeroInscription" />} />
            <Steps.Step title={<TranslatedMessage id="OTPCODE" />} />
          </Steps>
        </Col>
      </Row>
      {current === 0 && (
        <Form
          onFinish={(e) => {
            dispatch(preAuthAction(e.id));
            setInscriptionId(e.id);
          }}
        >
          <Form.Item
            hasFeedback
            name="id"
            validateStatus={preAuthError ? 'error' : ''}
            rules={[
              {
                required: true,
                message: 'Please input your inscription code!',
              },
            ]}
            help={preAuthError ? <TranslatedMessage id="idNotFound" /> : ''}
          >
            <Input
              allowClear
              placeholder={intl.formatMessage({ id: 'NumeroInscription' })}
              maxLength={9}
              minLength={9}
            />
          </Form.Item>
          <Space
            style={{ width: '100%' }}
            align="center"
            size={8}
            direction="vertical"
          >
            <Button
              type="primary"
              icon={<RightCircleOutlined />}
              htmlType="submit"
              loading={loadable.isLoading(preAuthLoadable)}
            >
              <TranslatedMessage id="next" />
            </Button>
            {/* <ChangePhoneNumber
              visible={changeModalVisible}
              onClose={() => setChangeModalVisible((v) => !v)}
            />
            <Button
              type="link"
              onClick={() => setChangeModalVisible((v) => !v)}
            >
              <TranslatedMessage id="changePhoneNumber" />
            </Button> */}
          </Space>
        </Form>
      )}
      {current === 1 && (
        <Form
          onFinish={(e) => {
            if (!!token) {
              dispatch(
                loginAction({
                  captcha: token,
                  password: e.otp,
                  username: inscriptionId,
                })
              );
            }
          }}
        >
          <Form.Item
            name="otp"
            rules={[{ required: true, message: 'Please input your OTP code!' }]}
            help={
              retry && (
                <Button
                  size="large"
                  type="link"
                  onClick={() => dispatch(preAuthAction(inscriptionId))}
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {!!preAuth?.phone ? (
                    <TranslatedMessage
                      id="smsOTPMessage"
                      values={{ number: <span dir="ltr">{preAuth.phone}</span> }}
                    />
                  ) : (
                    <TranslatedMessage
                      id="emailOTPMessage"
                      values={{ address: preAuth?.email }}
                    />
                  )}
                </Button>
              )
            }
          >
            <Input
              allowClear
              placeholder={intl.formatMessage({ id: 'OTPCODE' })}
              maxLength={6}
              minLength={6}
            />
          </Form.Item>
          <Row justify="center" gutter={[16, 16]} style={{ margin: ' 20px' }}>
            {loginError && (
              <Alert
                message={loginError && <TranslatedMessage id="loginFailed" />}
                type="error"
              />
            )}
          </Row>
          <Row justify="center">
            <Button
              type="link"
              icon={<RollbackOutlined />}
              style={{ margin: '0 8px' }}
              onClick={() => setCurrent(current - 1)}
            >
              {' '}
              <TranslatedMessage id="Retour" />
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              icon={<LoginOutlined />}
              loading={!token}
            >
              <TranslatedMessage id="Connection" />
            </Button>
          </Row>
        </Form>
      )}
    </Card>
  );
};

export default LoginComponent;
