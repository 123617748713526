import {
  Action,
  actionCreator,
  FailableAction,
  failableActionCreator,
  Loadable,
  PayloadAction,
  payloadActionCreator,
} from '../../types';
import * as t from 'io-ts';
import { withFallback } from 'io-ts-types';

export const ReclamationType = t.type({ id: t.number, type: t.string });
export type ReclamationType = t.TypeOf<typeof ReclamationType>;

export type State = {
  abus: Loadable<any>;
  postrecResult: Loadable<any>;
  declaration: Loadable<any>;
};

export interface Report {
  codeInscription: string;
  contenu: string;
  dateCreation: string;
  dateReponse: string;
  id: number;
  repondeur: string;
  reponse: string;
  titre: string;
  traite: string;
  traiter: string;
}

export interface Abuse {
  codeInscription: string;
  contenu?: string;
  dateCreation: string;
  dateReponse: string;
  id: number;
  repondeur: string;
  reponse: string;
  titre?: string;
  traite: boolean;
  traiter?: boolean;
  description?: string;
  effet?: string;
}

export interface Address {}

export interface Reclamation {
  codeInscription: string;
  dateCreation: string;
  dateReponse: string;
  id: number;
  repondeur: string;
  repondu: boolean;
  reponse: string;
  texte: string;
}

export interface Declaration {
  codeInscription: string;
  dateCreation: string;
  dateReponse: string;
  id: number;

  reponse: string;
}
export interface Vaccin {
  date: string;
  id: number;
  message: string;
  name: string;
  provider: string;
  status: string;
  user: User;
  vaccinator: string;
}

export interface User {
  abuses: Abuse[];
  activated: boolean;
  address: Address;
  birthDate: string;
  cin: string;
  cinReferral: string;
  declarations: Abuse[];
  email: string;
  firstName: string;
  langKey: string;
  lastName: string;
  login: string;
  operator: string;
  phoneNumber: string;
  reclamations: Reclamation[];
  registrationDate: string;
  registrationType: string;
}
export const emptyReport = [] as Report[];

export const Certif = t.type(
  {
    firstName: withFallback(t.string, ''),
    lastName: withFallback(t.string, ''),
    cin: withFallback(t.boolean,true),
    vaccinationAgent1: withFallback(t.string, ''),
    vaccinationAgent2: withFallback(t.string, ''),
    vaccinationCenter1: withFallback(t.string, ''),
    vaccinationCenter2: withFallback(t.string, ''),
    dateOfBirth: withFallback(t.string, ''),
    vaccinationCertificateId: withFallback(t.string, ''),
    idType: withFallback(t.string, ''),
    idNumber: withFallback(t.string, ''),
    vaccinType: withFallback(t.string, ''),
    assetId: withFallback(t.string, ''),
    vaccin1Reference: withFallback(t.string, ''),
    vaccin1Date: withFallback(t.string, ''),
    vaccin2Reference: withFallback(t.string, ''),
    vaccin2Date: withFallback(t.string, ''),
    nodeId: withFallback(t.string, ''),
    dateCovid: withFallback(t.string, ''),
    hasCovid: withFallback(t.boolean, false),
    signature: withFallback(t.string, ''),
    lotVaccin1: withFallback(t.string, ''),
    lotVaccin2: withFallback(t.string, ''),
    qrCode: withFallback(t.string, ''),
    covidDate: withFallback(t.string, ''),
    vaccineDTOS: t.array(
      t.type(
        {
          vaccinationCenter: withFallback(t.string, ''),
          vaccinDate: withFallback(t.string, ''),
          vaccineName: withFallback(t.string, ''),
          vaccinReference: withFallback(t.string, ''),
        },
        'VaccineDTO'
      )
    ),
  },
  'Certif'
);
export type Certif = t.TypeOf<typeof Certif>;

export const emptyCertif: Certif = {
  cin: true,
  firstName: '',
  lastName: '',
  vaccinationAgent1: '',
  vaccinationAgent2: '',
  vaccinationCenter1: '',
  vaccinationCenter2: '',
  dateOfBirth: '',
  vaccinationCertificateId: '',
  idType: '',
  idNumber: '',
  vaccinType: '',
  assetId: '',
  vaccin1Reference: '',
  vaccin1Date: '',
  vaccin2Reference: '',
  vaccin2Date: '',
  nodeId: '',
  dateCovid: '',
  hasCovid: false,
  signature: '',
  lotVaccin1: '',
  lotVaccin2: '',
  qrCode: '',
  covidDate: '',
  vaccineDTOS: [],
};

export interface SideEffectsResponse {
  addressPriseEnCharge?: string;
  adressePt?: string;
  cinp?: string;
  typeIdentifiant?: string;
  compteID?: number;
  created?: Date;
  dateHDebutMapi?: Date;
  dateHVaccin?: Date;
  dateNaissPt?: Date;
  descptMapi?: string;
  genrePt?: number;
  id?: number;
  idGouv?: string;
  lieuVaccin?: string;
  mailPt?: string;
  maladieChron?: boolean;
  nomPriseEnCharge?: string;
  nomPt?: string;
  nomVaccinFab?: string;
  numDeclar?: string;
  patientCodeInscription?: string;
  prenomPt?: string;
  priseEnChargeReaction?: boolean;
  reactionPostVaccinale?: string;
  telPriseEnCharge?: string;
  telPt?: string;
}

export const emptySideEffectResponse = {} as SideEffectsResponse;
export type ReportRequestBody = {
  type?: number;
  contenu: string;
};
export type GetAbusAction = Action<'Report/GetAbus'>;
export const getAbusAction = actionCreator<GetAbusAction>('Report/GetAbus');

export type GetAbusResultAction = FailableAction<'Report/GetAbus_result', any>;
export const getAbusResultAction = failableActionCreator<GetAbusResultAction>(
  'Report/GetAbus_result'
);

export type UpdateAbusAction = PayloadAction<'Report/UpdateAbus', any>;
export const updateAbusAction = payloadActionCreator<UpdateAbusAction>(
  'Report/UpdateAbus'
);

export type UpdateAbusResultAction = FailableAction<
  'Report/UpdateAbus_result',
  any
>;
export const updateAbusResultAction = failableActionCreator<UpdateAbusResultAction>(
  'Report/UpdateAbus_result'
);

export type PostAbusAction = PayloadAction<'Report/PostAbus', any>;
export const postAbusAction = payloadActionCreator<PostAbusAction>(
  'Report/PostAbus'
);

export type PostAbusResultAction = FailableAction<
  'Report/PostAbus_result',
  any
>;
export const postAbusResultAction = failableActionCreator<PostAbusResultAction>(
  'Report/PostAbus_result'
);

export type UpdateReclamationAction = PayloadAction<
  'Report/UpdateReclamation',
  any
>;
export const updateReclamationAction = payloadActionCreator<UpdateReclamationAction>(
  'Report/UpdateReclamation'
);

export type UpdateReclamationResultAction = FailableAction<
  'Report/UpdateReclamation_result',
  any
>;
export const updateReclamationResultAction = failableActionCreator<UpdateReclamationResultAction>(
  'Report/UpdateReclamation_result'
);

export type PostReclamationAction = PayloadAction<
  'Report/PostReclamation',
  any
>;
export const postReclamationAction = payloadActionCreator<PostReclamationAction>(
  'Report/PostReclamation'
);

export type PostReclamationResultAction = FailableAction<
  'Report/PostReclamation_result',
  any
>;
export const postReclamationResultAction = failableActionCreator<PostReclamationResultAction>(
  'Report/PostReclamation_result'
);

export type GetDeclarationAction = Action<'Report/GetDeclaration'>;
export const getDeclarationAction = actionCreator<GetDeclarationAction>(
  'Report/GetDeclaration'
);

export type GetDeclarationResultAction = FailableAction<
  'Report/GetDeclaration_result',
  any
>;
export const getDeclarationResultAction = failableActionCreator<GetDeclarationResultAction>(
  'Report/GetDeclaration_result'
);

export type UpdateDeclarationAction = PayloadAction<
  'Report/UpdateDeclaration',
  any
>;
export const updateDeclarationAction = payloadActionCreator<UpdateDeclarationAction>(
  'Report/UpdateDeclaration'
);

export type UpdateDeclarationResultAction = FailableAction<
  'Report/UpdateDeclaration_result',
  any
>;
export const updateDeclarationResultAction = failableActionCreator<UpdateDeclarationResultAction>(
  'Report/UpdateDeclaration_result'
);

export type PostDeclarationAction = PayloadAction<
  'Report/PostDeclaration',
  any
>;
export const postDeclarationAction = payloadActionCreator<PostDeclarationAction>(
  'Report/PostDeclaration'
);

export type PostDeclarationResultAction = FailableAction<
  'Report/PostDeclaration_result',
  any
>;
export const postDeclarationResultAction = failableActionCreator<PostDeclarationResultAction>(
  'Report/PostDeclaration_result'
);

export type GetCertifAction = Action<'Report/GetCertif'>;
export const getCertifAction = actionCreator<GetCertifAction>(
  'Report/GetCertif'
);

export type GetCertifResultAction = FailableAction<
  'Report/GetCertif_result',
  any
>;
export const getCertifResultAction = failableActionCreator<GetCertifResultAction>(
  'Report/GetCertif_result'
);

export type Actions =
  | UpdateAbusAction
  | UpdateAbusResultAction
  | GetAbusAction
  | GetAbusResultAction
  | PostAbusAction
  | PostAbusResultAction
  | UpdateReclamationAction
  | UpdateReclamationResultAction
  | PostReclamationAction
  | PostReclamationResultAction
  | UpdateDeclarationAction
  | UpdateDeclarationResultAction
  | GetDeclarationAction
  | GetDeclarationResultAction
  | PostDeclarationAction
  | PostDeclarationResultAction;
