import {
  Action,
  actionCreator,
  FailableAction,
  failableActionCreator,
  Loadable,
  PayloadAction,
  payloadActionCreator,
} from '../../types';

export type State = {
  qResponse: Loadable<any>;
  response: Loadable<any>;
};

export type Questionresponse = {
  codeQuestion: number;
  reponse: string;
};
export interface Question {
  id: number;
  libelleQuestion?: string;

  orderQuestion: number | null;
  placeholderQuestion?: string;

  questionParent: number | null;
  rqQuestion?: string;
  scoreMax?: number;
  scoreMin?: number;
  typeQuestion?: TypeQuestion;
}

export interface TypeQuestion {
  descTpQuestion?: string;
  idTypeQuestion?: number;
  libTpQuest?: string;
}

export const emptyQuestion: Question[] = [] as Question[];

export interface Reponse {
  dateReponse?: string;
  descriptionReponse?: string;
  question?: Question;
  reponseInscrit?: number;
  reponseInscritDate?: string;
  reponseInscritText?: string;
}
export const emptyResponse = [] as Reponse[];

export type GetReponseAction = Action<'Report/GetReponse'>;
export const getReponseAction = actionCreator<GetReponseAction>('Report/GetReponse');

export type GetReponseResultAction = FailableAction<'Report/GetReponse_result', any>;
export const getReponseResultAction = failableActionCreator<GetReponseResultAction>('Report/GetReponse_result');

export type UpdateQuestionAction = PayloadAction<'Report/UpdateQuestion', any>;
export const updateQuestionAction = payloadActionCreator<UpdateQuestionAction>('Report/UpdateQuestion');

export type UpdateQuestionResultAction = FailableAction<'Report/UpdateQuestion_result', any>;
export const updateQuestionResultAction =
  failableActionCreator<UpdateQuestionResultAction>('Report/UpdateQuestion_result');

export type Actions =
  | UpdateQuestionAction
  | UpdateQuestionResultAction
  | GetReponseAction
  | GetReponseResultAction;
