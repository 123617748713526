import { FormOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Typography } from 'antd';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getAbusAction, postAbusAction, updateAbusAction } from '../../shared/state/rapports';
import { State } from '../../shared/state/store';
import { TranslatedMessage } from '../../translations/data';

const mapStateToProps = ({ reports: { abus } }: State) => ({});
const mapDispatchToProps = { getAbus: getAbusAction, postAbus: postAbusAction, updateAbus: updateAbusAction };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

const Abus = ({ postAbus }: Props) => {
  const [isAbusVisible, setAbusVisible] = React.useState(false);
  const [lastactive, setlastactive] = React.useState(false);
  const [form] = Form.useForm();
  const intl = useIntl();

  React.useEffect(() => {
    setlastactive(Number(localStorage.getItem('lastAbusePost')) + 300000 < Date.now());
  }, [setAbusVisible]);

  return (
    <>
      <Button block onClick={() => setAbusVisible(true)}>
        <TranslatedMessage id="AbusTitle" />
      </Button>
      <Modal
        title={
          <Row gutter={16}>
            <Col>
              <FormOutlined />
            </Col>
            <Col>
              <Typography.Title level={5}>
                <TranslatedMessage id="AbusTitle" />
              </Typography.Title>
            </Col>
          </Row>
        }
        footer={[
          <Button
            form="userAddForm"
            key="close"
            onClick={() => {
              setAbusVisible(false);
              form.resetFields();
            }}
          >
            <TranslatedMessage id="Cancel" defaultMessage="Cancel" />
          </Button>,
          <Button type="primary" form="userAddForm" key="submit" htmlType="submit" disabled={!lastactive}>
            <TranslatedMessage id="Connection" defaultMessage="Submit" />
          </Button>,
        ]}
        visible={isAbusVisible}
        onCancel={() => {
          setAbusVisible(false);
          form.resetFields();
        }}
        destroyOnClose={true}
      >
        <Row>
          <Form
            size="small"
            id="userAddForm"
            form={form}
            layout="vertical"
            key="form"
            style={{ width: '100%' }}
            onFinish={(values) => {
              postAbus(values);
              localStorage.setItem('lastAbusePost', `${Date.now()}`);
              setAbusVisible(false);
              form.resetFields();
            }}
          >
            <Form.Item
              required
              key="contenu"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'abusWarrningMessage' }),
                },
              ]}
              name="contenu"
              label={
                <Typography.Title level={5}>
                  <TranslatedMessage id="addAbus" />
                </Typography.Title>
              }
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Form>
        </Row>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Abus);
