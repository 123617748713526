import { UserAddOutlined } from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Divider,
  Form,
  Input,
  List,
  Modal,
  Result,
  Select,
  Steps,
  Typography,
} from "antd";
import { taskEither } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import * as React from "react";
import { useIntl } from "react-intl";
import { TranslatedMessage } from "../../translations/data";
import {
  confirmSponsorship,
  getRelations,
  getSponsorees,
  sendSponsoreeCode,
  Sponsoree,
} from "./api";
import { Relationship } from "./types";

export default () => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(1);
  const [sponsoreeCode, setSponsoreeCode] = React.useState<string>("");
  const [relation, setRelation] = React.useState<number | undefined>(undefined);
  const [relations, setRelations] = React.useState<Relationship[] | "Loading">(
    "Loading"
  );
  const [sponsorees, setSponsorees] = React.useState<Sponsoree[] | "Loading">(
    "Loading"
  );
  const intl = useIntl();

  const [codeForm] = Form.useForm();
  const [infoForm] = Form.useForm();

  const loadSponsorees = pipe(
    getSponsorees(),
    taskEither.bimap(console.error, setSponsorees)
  );

  React.useEffect(() => {
    if (modalVisible) {
      codeForm.resetFields();
      infoForm.resetFields();
      setCurrentStep(1);
      setSponsoreeCode("");
      setRelation(undefined);
      pipe(
        getRelations(),
        taskEither.bimap(
          (e) => console.error(e),
          (r) => setRelations(r)
        )
      )();

      setSponsorees("Loading");
      loadSponsorees();
    }
  }, [modalVisible]);

  return (
    <>
      <Button
        icon={<UserAddOutlined />}
        block
        onClick={() => setModalVisible(true)}
      >
        <TranslatedMessage id="ParrainerEnfant" />
      </Button>
      <Modal
        title={<TranslatedMessage id="ParrainageEnfant" />}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        cancelText={<TranslatedMessage id="Cancel" />}
        okText={
          currentStep !== 3 ? (
            <TranslatedMessage id="next" />
          ) : (
            <TranslatedMessage id="finish" />
          )
        }
        onOk={() =>
          currentStep === 1
            ? codeForm.submit()
            : currentStep === 2
            ? infoForm.submit()
            : setModalVisible(false)
        }
      >
        <Steps current={currentStep}>
          <Steps.Step></Steps.Step>
          <Steps.Step></Steps.Step>
          <Steps.Step></Steps.Step>
        </Steps>
        <div style={{ height: "16px" }} />
        {currentStep === 1 && (
          <Form<{ sponsoreeCode: string }>
            form={codeForm}
            size="small"
            layout="vertical"
            onFinish={(v) => {
              pipe(
                sendSponsoreeCode(v.sponsoreeCode),
                taskEither.bimap(
                  (e) => {
                    console.error(e);
                    codeForm.setFields([
                      {
                        name: "sponsoreeCode",
                        errors: [
                          intl.formatMessage({
                            id: "sponsoreeCodeError",
                          }),
                        ],
                      },
                    ]);
                  },
                  () => {
                    setSponsoreeCode(v.sponsoreeCode);
                    setCurrentStep(2);
                  }
                )
              )();
            }}
          >
            <Form.Item
              required
              name="sponsoreeCode"
              key="sponsoreeCode"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "sponsoreeWarningMessage",
                  }),
                },
              ]}
              label={
                <Typography.Title level={5}>
                  <TranslatedMessage id="sponsoree" />
                </Typography.Title>
              }
            >
              <Input />
            </Form.Item>
          </Form>
        )}
        {currentStep === 2 && (
          <Form<{
            typeSponsoree: number;
            otp: string;
            desc: string | undefined;
          }>
            form={infoForm}
            size="small"
            layout="vertical"
            onValuesChange={(_, v) => {
              setRelation(v.typeSponsoree);
            }}
            onFinish={(v) => {
              pipe(
                confirmSponsorship({ ...v, sponsoreeCode }),
                taskEither.bimap(
                  (e) => {
                    console.error(e);
                    infoForm.setFields([
                      {
                        name: "otp",
                        errors: [
                          intl.formatMessage({
                            id: "otpError",
                          }),
                        ],
                      },
                    ]);
                  },
                  () => setCurrentStep(3)
                ),
                taskEither.chain(() => loadSponsorees)
              )();
            }}
          >
            <Form.Item
              required
              name="typeSponsoree"
              key="typeSponsoree"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "relationWarningMessage",
                  }),
                },
              ]}
              label={
                <Typography.Title level={5}>
                  <TranslatedMessage id="relation" />
                </Typography.Title>
              }
            >
              <Select loading={relations === "Loading"}>
                {relations === "Loading"
                  ? []
                  : relations.map((r) => (
                      <Select.Option value={r.id} key={r.id}>
                        {r.type}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
            {relation === 7 && (
              <Form.Item
                required
                name="description"
                key="description"
                label={
                  <Typography.Title level={5}>
                    <TranslatedMessage id="description" />
                  </Typography.Title>
                }
              >
                <Input />
              </Form.Item>
            )}
            <Form.Item
              required
              name="otp"
              key="otp"
              rules={[{ required: true }]}
              label={
                <Typography.Title level={5}>
                  <TranslatedMessage id="OTPCODE" />
                </Typography.Title>
              }
            >
              <Input />
            </Form.Item>
          </Form>
        )}
        {currentStep === 3 && <Result status="success" />}
        <Divider />
        <Typography.Title level={4}>
          <TranslatedMessage id="sponsorees" />
        </Typography.Title>
        <List
          loading={sponsorees === "Loading"}
          dataSource={sponsorees === "Loading" ? [] : sponsorees}
          renderItem={(s) => (
            <Descriptions>
              <Descriptions.Item label={<TranslatedMessage id="Prenom" />}>
                {s.prenomInscrit}
              </Descriptions.Item>
              <Descriptions.Item label={<TranslatedMessage id="Nom" />}>
                {s.nomInscrit}
              </Descriptions.Item>
              <Descriptions.Item label={<TranslatedMessage id="Inscription" />}>
                {s.codeInscription}
              </Descriptions.Item>
            </Descriptions>
          )}
        />
      </Modal>
    </>
  );
};
