import { Col, Image, Row } from 'antd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';

import GreenphoneCard from '../../shared/layout/GreenphoneCard';
import { StaticSelectorWarper } from '../../shared/LocaleSelector';
import { State } from '../../shared/state/store';
import logoar from './../../assets/logo-Arabe.png';
import logo from './../../assets/logoMS2.png';
import Login from './Login';

const LandingLayout = styled.div`
  width: 100%;
  height: 100%;
  display: grid;

  grid-template-rows: auto;
`;

const LandingHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;

  img {
    height: 64px;
    margin: 0 auto 8px;
  }
  div {
    font-size: 20px;
  }
`;

const LandingFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: auto;
`;

const LandingContent = styled.div`
  margin-bottom: auto;
  margin-top: auto;
`;
const mapStateToProps = ({ locale }: State) => ({
  locale: locale,
});
type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;
const Authentication = ({ locale }: Props) => (
  <LandingLayout style={{width:'calc(100vw - 20px'}}>
    <LandingHeader>
      {locale === 'fr' ? (
        <Image
          preview={false}
          src={logo}
          style={{
            height: 'auto',
            width: '100%',
            /* even more control with max-width */
            maxWidth: '200px',
          }}
        />
      ) : null}
      {locale === 'ar-TN' ? (
        <Image
          preview={false}
          src={logoar}
          style={{
            height: 'auto',
            width: '100%',
            /* even more control with max-width */
            maxWidth: '200px',
          }}
        />
      ) : null}
    </LandingHeader>
    <LandingContent>
      <Row justify="center">
        <Col lg={12} sm={24}>
          <Login />
        </Col>
      </Row>
    </LandingContent>
    <LandingFooter>
      <Row justify="center" gutter={[16, 16]}>
        <Col span={16}>
          <Row
            justify="center"
            gutter={[16, 16]}
            style={{ textAlign: 'center' }}
          >
            <FormattedMessage id="messageForTunisiansAbroad" />
          </Row>
        </Col>
        <Col span={16}>
          <Row justify="center" gutter={[16, 16]}>
            <GreenphoneCard />
          </Row>
        </Col>
        <Col span={16}>
          <Row justify="center" gutter={[16, 16]}>
            <StaticSelectorWarper />
          </Row>
        </Col>
      </Row>
    </LandingFooter>
  </LandingLayout>
);

export default connect(mapStateToProps)(Authentication);
