import { CancelToken } from 'axios';
import { task } from 'fp-ts';
import { pipe } from 'fp-ts/lib/pipeable';
import { lens } from 'lens.ts';
import * as effects from 'redux-saga/effects';

import { foldEitherToAction, Loading, NotRequested } from '../../types';
import { takeLatest } from '../saga';
import * as api from './api';
import * as t from './types';

export const initialState: t.State = {
  qResponse: NotRequested,
  response: NotRequested,
};
const _l = lens<t.State>();
export const reducer = (s: t.State = initialState, a: t.Actions): t.State => {
  switch (a.type) {
    case 'Report/GetReponse':
      return _l.response.set(Loading)(s);
    case 'Report/GetReponse_result':
      return _l.response.set(a.payload)(s);
    case 'Report/UpdateQuestion':
      return _l.qResponse.set(Loading)(s);
    case 'Report/UpdateQuestion_result':
      return _l.qResponse.set(a.payload)(s);

    default:
      return s;
  }
};

function* getResponseSaga(_: t.GetReponseAction, token: CancelToken) {
  yield yield pipe(
    api.getResponse(token),
    task.map(foldEitherToAction(t.getReponseResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  );
}

function* updateResponseSaga(a: t.UpdateQuestionAction, token: CancelToken) {
  yield yield pipe(
    api.updateResponse(a.payload, token),
    task.map(foldEitherToAction(t.updateQuestionResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  );
}

export function* saga() {
  yield effects.all([
    takeLatest('Report/UpdateQuestion', updateResponseSaga),
    takeLatest('Report/GetReponse', getResponseSaga),
  ]);
}
