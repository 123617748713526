import { Typography } from "antd";
import styled from "styled-components";
import { ReactComponent as Flag } from "../assets/flag.svg";
import ministereSanteLogo from "../assets/ministere sante.jpg";
import ministereTechnoLogo from "../assets/ministeretechno.png";

const Logo = styled.div<{ offset?: boolean }>`
  padding-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${(props) => (props.offset ? "transform: translateY(-24px);" : "")}
  & > *:first-child {
    width: 100%;
    max-width: 100px;
    margin-bottom: 12px;
  }
`;

const Heading = styled(Typography.Title)<{ offset?: boolean }>`
  text-align: center;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  ${(props) => (props.offset ? "transform: translateY(-24px);" : "")}
`;

const LogosDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0;
  margin-bottom: 16px;
`;

const CertificateHeader = () => (
  <LogosDiv>
    <Logo>
      <img src={ministereSanteLogo} />
    </Logo>
    <Logo offset>
      <Flag />
    </Logo>
    <Logo>
      <img src={ministereTechnoLogo} />
    </Logo>
    <Heading level={5}>وزارة الصحة</Heading>
    <Heading level={5} offset>
      الجمهورية التونسية
    </Heading>
    <Heading level={5}>وزارة تكنولوجيات الاتصال</Heading>
    <Heading level={5}>Ministry of Health</Heading>
    <Heading level={5} offset>
      Republic of Tunisia
    </Heading>
    <Heading level={5}>Ministry of Communication Technologies</Heading>
  </LogosDiv>
);

export default CertificateHeader;
