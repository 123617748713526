import { Select, Space } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';

import { locales, TranslatedMessage } from '../translations/data';
import { setLocaleAction } from './state/Locale';
import { updateLanguageAction } from './state/Login';
import { State } from './state/store';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export const countryToFlag = (isoCode: string) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
};

const mapStateToProps = ({ locale }: State) => ({ locale: locale });

const mapDispatchToProps = {
  updateLocale: updateLanguageAction,
  setSelector: setLocaleAction,
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const Selector = ({ locale, updateLocale }: Props) => {
  return (
    <Select
      value={locale}
      onChange={(l) =>
        updateLocale(l === 'ar-TN' ? 'AR' : l === 'fr' ? 'FR' : 'EN')
      }
      showArrow
    >
      {locales.map((l) => (
        <Select.Option key={l} value={l}>
          <div style={{ marginLeft: '15px' }}>
            <Space>
              {
                l === 'ar-TN' ? (
                  <span>{countryToFlag('TN')}</span>
                ) : (
                  <span>{countryToFlag(l)}</span>
                )
              }
              <TranslatedMessage id={l === 'ar-TN' ? 'ar' : l} />
            </Space>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Selector);

const StaticSelector = ({ locale, setSelector }: Props) => (
  <Select
    value={locale}
    onChange={setSelector}
    style={{ width: 150 }}
    size="large"
    showArrow
  >
    {locales.map((l) => (
      <Select.Option key={l} value={l}>
        <div style={{ marginLeft: '15px' }}>
          <Space>
            {l === 'ar-TN' ? (
              <span>{countryToFlag('TN')}</span>
            ) : (
              <span>{countryToFlag(l)}</span>
            )}
            <TranslatedMessage id={l === 'ar-TN' ? 'ar' : l} />
          </Space>
        </div>
      </Select.Option>
    ))}
  </Select>
);

export const StaticSelectorWarper = connect(
  mapStateToProps,
  mapDispatchToProps
)(StaticSelector);
