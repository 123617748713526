import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { State } from '../../shared/state/store';
import { loadable } from '../../shared/types';

const mapStateToProps = ({ auth: { account } }: State) => ({
  isLoggedIn: loadable.isOk(account),
});

type Props = RouteProps & ReturnType<typeof mapStateToProps>;

const PublicRoute = ({ isLoggedIn, ...rest }: Props) => (isLoggedIn ? <Redirect to="/" /> : <Route {...rest} />);

export default connect(mapStateToProps)(PublicRoute);
