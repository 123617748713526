import { CancelToken } from 'axios';
import { task } from 'fp-ts';
import { pipe } from 'fp-ts/lib/pipeable';
import { lens } from 'lens.ts';
import * as effects from 'redux-saga/effects';

import { foldEitherToAction, Loading, NotRequested } from '../../types';
import { takeLatest } from '../saga';
import * as api from './api';
import * as t from './types';

export const initialState: t.State = {
  abus: NotRequested,
  postrecResult: NotRequested,
  declaration: NotRequested,
};

const _l = lens<t.State>();
export const reducer = (s: t.State = initialState, a: t.Actions): t.State => {
  switch (a.type) {
    case 'Report/PostAbus':
      return _l.abus.set(Loading)(s);
    case 'Report/PostAbus_result':
      return _l.abus.set(a.payload)(s);
    case 'Report/GetAbus':
      return _l.abus.set(Loading)(s);
    case 'Report/GetAbus_result':
      return _l.abus.set(a.payload)(s);
    case 'Report/UpdateAbus':
      return _l.abus.set(Loading)(s);
    case 'Report/UpdateAbus_result':
      return _l.abus.set(a.payload)(s);
    case 'Report/PostReclamation':
      return _l.postrecResult.set(Loading)(s);
    case 'Report/PostReclamation_result':
      return _l.postrecResult.set(a.payload)(s);
    case 'Report/PostDeclaration':
      return _l.declaration.set(Loading)(s);
    case 'Report/PostDeclaration_result':
      return _l.declaration.set(a.payload)(s);
    case 'Report/GetDeclaration':
      return _l.declaration.set(Loading)(s);
    case 'Report/GetDeclaration_result':
      return _l.declaration.set(a.payload)(s);
    case 'Report/UpdateDeclaration':
      return _l.declaration.set(Loading)(s);
    case 'Report/UpdateDeclaration_result':
      return _l.declaration.set(a.payload)(s);
    default:
      return s;
  }
};

function* getAbusSaga(_: t.GetAbusAction, token: CancelToken) {
  yield yield pipe(
    api.getAbus(token),
    task.map(foldEitherToAction(t.getAbusResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  );
}

function* updateAbusSaga(a: t.UpdateAbusAction, token: CancelToken) {
  yield yield pipe(
    api.updateAbus(a.payload, token),
    task.map(foldEitherToAction(t.updateAbusResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  );
}
function* postAbusSaga(a: t.PostAbusAction, token: CancelToken) {
  yield yield pipe(
    api.postAbus(a.payload, token),
    task.map(foldEitherToAction(t.postAbusResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  );
}

function* updateReclamationsSaga(
  a: t.UpdateReclamationAction,
  token: CancelToken
) {
  yield yield pipe(
    api.updateReclamations(a.payload, token),
    task.map(foldEitherToAction(t.updateReclamationResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  );
}

function* postReclamationsSaga(a: t.PostReclamationAction, token: CancelToken) {
  yield yield pipe(
    api.postReclamations(a.payload, token),
    task.map(foldEitherToAction(t.postReclamationResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  );
}
function* getDeclarationSaga(_: t.GetDeclarationAction, token: CancelToken) {
  yield yield pipe(
    api.getSideEffects(token),
    task.map(foldEitherToAction(t.getDeclarationResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  );
}
function* updateDeclarationSaga(
  a: t.UpdateDeclarationAction,
  token: CancelToken
) {
  yield yield pipe(
    api.updateSideEffects(a.payload, token),
    task.map(foldEitherToAction(t.updateDeclarationResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  );
}

function* postDeclarationSaga(a: t.PostDeclarationAction, token: CancelToken) {
  yield yield pipe(
    api.postSideEffects(a.payload, token),
    task.map(foldEitherToAction(t.postDeclarationResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  );
}

export function* saga() {
  yield effects.all([
    takeLatest('Report/GetAbus', getAbusSaga),
    takeLatest('Report/PostAbus', postAbusSaga),
    takeLatest('Report/UpdateAbus', updateAbusSaga),
    takeLatest('Report/PostReclamation', postReclamationsSaga),
    takeLatest('Report/UpdateReclamation', updateReclamationsSaga),
    takeLatest('Report/GetDeclaration', getDeclarationSaga),
    takeLatest('Report/PostDeclaration', postDeclarationSaga),
    takeLatest('Report/UpdateDeclaration', updateDeclarationSaga),
  ]);
}
