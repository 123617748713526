import { setLocaleAction } from './../Locale';
import { getDeclarationAction } from './../rapports/types';
import { getReponseAction } from './../questions/types';
import { CancelToken } from 'axios';
import { either, task } from 'fp-ts';
import { pipe } from 'fp-ts/lib/pipeable';
import { lens } from 'lens.ts';
import * as effects from 'redux-saga/effects';

import { clearCookies } from '../../axios';
import {
  foldEitherToAction,
  Loading,
  NotRequested,
  result,
  Unpacked,
} from '../../types';
import { takeLatest } from '../saga';
import * as api from './api';
import * as t from './types';

export const initialState: t.State = {
  preAuthResult: NotRequested,
  loginResult: NotRequested,
  account: NotRequested,
  updateAccount: NotRequested,
  sexe: NotRequested,
  preChangePhoneResult: NotRequested,
  confirmPhoneResult: NotRequested,
  cancelInscriptionResult: NotRequested,
  changeRdvResult: NotRequested,
  updateLanguageResult: NotRequested,
};

const _l = lens<t.State>();
export const reducer = (s: t.State = initialState, a: t.Actions): t.State => {
  switch (a.type) {
    case 'Language/UpdateLanguage':
      return _l.preAuthResult.set(Loading)(s);
    case 'Language/UpdateLanguage_result':
      return pipe(s, _l.preAuthResult.set(a.payload));
    case 'ACCOUNT/preAuth':
      return _l.preAuthResult.set(Loading)(s);
    case 'ACCOUNT/preAuth_result':
      return pipe(s, _l.preAuthResult.set(a.payload));
    case 'ACCOUNT/GetAccount':
      return pipe(s, _l.account.set(Loading));
    case 'ACCOUNT/GetAccount_result':
      return pipe(s, _l.account.set(a.payload));
    case 'ACCOUNT/GetSexe':
      return pipe(s, _l.sexe.set(Loading));
    case 'ACCOUNT/GetSexe_result':
      return pipe(s, _l.sexe.set(a.payload));
    case 'ACCOUNT/ChangeRdv':
      return pipe(s, _l.changeRdvResult.set(Loading));
    case 'ACCOUNT/ChangeRdv_result':
      return pipe(s, _l.changeRdvResult.set(a.payload));
    case 'ACCOUNT/UpdateAccount':
      return pipe(s, _l.updateAccount.set(Loading));
    case 'ACCOUNT/UpdateAccount_result':
      return pipe(s, _l.updateAccount.set(a.payload));
    case 'ACCOUNT/login':
      return pipe(s, _l.loginResult.set(Loading));
    case 'ACCOUNT/login_result':
      return pipe(s, _l.loginResult.set(a.payload));
    case 'ACCOUNT/preChangePhoneNumber':
      return pipe(s, _l.preChangePhoneResult.set(Loading));
    case 'ACCOUNT/preChangePhoneNumber_result':
      return pipe(s, _l.preChangePhoneResult.set(a.payload));
    case 'ACCOUNT/confirmPhone':
      return pipe(s, _l.confirmPhoneResult.set(Loading));
    case 'ACCOUNT/confirmPhone_result':
      return pipe(s, _l.confirmPhoneResult.set(a.payload));
    case 'ACCOUNT/CancelInscription':
      return pipe(s, _l.cancelInscriptionResult.set(Loading));
    case 'ACCOUNT/CancelInscription_result':
      return pipe(s, _l.cancelInscriptionResult.set(a.payload));
    default:
      return s;
  }
};

function* loginSaga(a: t.LoginAction, token: CancelToken) {
  yield yield pipe(
    api.signIn(a.payload, token),
    task.map(
      either.fold(
        (e) => effects.all([effects.put(t.loginResultAction.err(e.message))]),
        (r) =>
          effects.all([
            effects.put(t.getAccountAction()),
            effects.put(t.loginResultAction.ok(r)),
          ])
      )
    ),
    (e) => effects.call(e)
  );
}

function* accountSaga(_: t.GetAccountAction, token: CancelToken) {
  yield yield pipe(
    api.getAccount(token),
    task.map(
      either.fold(
        (e) =>
          effects.all([effects.put(t.getAccountResultAction.err(e.message))]),
        (r) =>
          effects.all([
            effects.put(t.getAccountResultAction.ok(r)),
            effects.put(getDeclarationAction()),
            effects.put(getReponseAction()),
            r.language === 'EN'
              ? effects.put(setLocaleAction('en'))
              : r.language === 'FR'
              ? effects.put(setLocaleAction('fr'))
              : effects.put(setLocaleAction('ar-TN')),
          ])
      )
    ),
    (e) => effects.call(e)
  );
}

function* vaccinSaga(_: t.GetVacAction, token: CancelToken) {
  yield yield pipe(
    api.getVac(token),
    task.map(
      either.fold(
        (e) => effects.all([effects.put(t.getVacResultAction.err(e.message))]),
        (r) => effects.all([effects.put(t.getVacResultAction.ok(r))])
      )
    ),
    (e) => effects.call(e)
  );
}

function* sexeSaga(_: t.GetSexeAction, token: CancelToken) {
  yield yield pipe(
    api.getSexe(token),
    task.map(foldEitherToAction(t.getSexeResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  );
}
function* changeRdvSaga(_: t.ChangeRdvAction) {
  try {
    const res = yield effects.call(api.changeRDV);
    // console.log(res);
    yield effects.all([
      effects.put(t.changeRdvResultAction.ok(res?.data)),
      effects.put(t.getAccountAction()),
      effects.put(getDeclarationAction()),
      effects.put(getReponseAction()),
    ]);
  } catch (error) {
    // console.log(error.response.data);
    yield effects.all([
          //@ts-ignore
      effects.put(t.changeRdvResultAction.err(error.response.data)),
    ]);
  }
}

function* cancelInscriptionSaga(
  a: t.CancelInscriptionAction,
  token: CancelToken
) {
  yield yield pipe(
    api.cancelInscription(a.payload, token),
    task.map(foldEitherToAction(t.cancelInscriptionResultAction)),
    task.map((a) =>
      result.isOk(a.payload)
        ? effects.all([effects.put(t.getAccountAction()), effects.put(a)])
        : effects.put(a)
    ),
    (e) => effects.call(e)
  );
}

function* updateAccountSaga(a: t.UpdateAccountAction, token: CancelToken) {
  yield yield pipe(
    api.updateAccount(a.payload, token),
    task.map(
      either.fold(
        (e) => effects.all([effects.put(t.updateAccountResultAction.err(e))]),
        (r) =>
          effects.all([
            effects.put(t.getAccountAction()),
            effects.put(t.updateAccountResultAction.ok(r)),
          ])
      )
    ),
    (e) => effects.call(e)
  );
}

function* updateLanguageSaga(a: t.UpdateLanguageAction, token: CancelToken) {
  yield yield pipe(
    api.postLanguage(a.payload, token),
    task.map(
      either.fold(
        (e) => effects.all([effects.put(t.updateLanguageResultAction.err(e))]),
        (r) =>
          effects.all([
            effects.put(t.updateLanguageResultAction.ok(r)),
            effects.put(t.getAccountAction()),
          ])
      )
    ),
    (e) => effects.call(e)
  );
}

function* preAuthSaga(a: t.PreAuthAction, token: CancelToken) {
  yield yield pipe(
    api.preAuth(a.payload, token),
    task.map(foldEitherToAction(t.preAuthActionResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  );
}

function* preChangePhoneSaga(
  a: t.PreChangePhoneNumberAction,
  token: CancelToken
) {
  yield yield pipe(
    api.preChangePhoneNumber(a.payload, token),
    task.map(foldEitherToAction(t.preChangePhoneNumberActionResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  );
}

function* confirmChangePhoneSaga(a: t.ConfirmPhoneAction, token: CancelToken) {
  yield yield pipe(
    api.confirmPhoneNumber(a.payload, token),
    task.map(foldEitherToAction(t.confirmPhoneResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  );
}

function* logoutSaga(_: t.LogoutAction, token: CancelToken) {
  const res: Unpacked<typeof api.logout> = yield effects.call(
    api.logout(token)
  );
  const action = foldEitherToAction(t.logoutResultAction)(res);
  clearCookies()();
  yield effects.all([effects.put(action), effects.put(t.getAccountAction())]);
}

export function* saga() {
  yield effects.all([
    takeLatest('ACCOUNT/preAuth', preAuthSaga),
    takeLatest('ACCOUNT/login', loginSaga),
    takeLatest('ACCOUNT/GetAccount', accountSaga),
    takeLatest('ACCOUNT/logout', logoutSaga),
    takeLatest('ACCOUNT/UpdateAccount', updateAccountSaga),
    takeLatest('ACCOUNT/GetSexe', sexeSaga),
    takeLatest('ACCOUNT/confirmPhone', confirmChangePhoneSaga),
    takeLatest('ACCOUNT/preChangePhoneNumber', preChangePhoneSaga),
    takeLatest('ACCOUNT/CancelInscription', cancelInscriptionSaga),
    takeLatest('ACCOUNT/GetVac', vaccinSaga),
    takeLatest('ACCOUNT/ChangeRdv', changeRdvSaga),
    takeLatest('Language/UpdateLanguage', updateLanguageSaga),
  ]);
}
