import {
  connectRouter,
  routerMiddleware,
  RouterState,
} from "connected-react-router";
import { createBrowserHistory } from "history";
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxSaga from "redux-saga";
import * as effects from "redux-saga/effects";

import * as notifications from "../Notifications";
import * as login from "./Login";
import * as reports from "./rapports";
import * as questions from "./questions";
import { getAccountAction } from "./Login";

import * as locale from "./Locale";
export type Actions = login.Actions | reports.Actions | questions.Actions;

function* initSaga() {
  yield effects.all([effects.put(getAccountAction())]);
}
function* rootSaga() {
  yield effects.fork(initSaga);
  yield effects.all([login.saga(), reports.saga(), questions.saga()]);
}

const sagaMiddleware = reduxSaga();
export const history = createBrowserHistory({ basename: "/vac" });

export type State = {
  router: RouterState;
  locale: locale.State;
  auth: login.State;
  reports: reports.State;
  question: questions.State;
  notifications: notifications.State;
};

declare module "react-redux" {
  interface DefaultRootState extends State {}
}

const appReducer = combineReducers<State>({
  auth: login.reducer,

  locale: locale.reducer,
  reports: reports.reducer,
  question: questions.reducer,
  notifications: notifications.reducer,
  router: connectRouter(history),
});
const rootReducer = (state: State | undefined, action: AnyAction) => {
  if (action.type === "ACCOUNT/logout_result") {
    state = undefined;
  }

  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  composeWithDevTools({ trace: true })(
    applyMiddleware(sagaMiddleware, routerMiddleware(history))
  )
);

sagaMiddleware.run(rootSaga);

export default store;
