import { Button, Dropdown, Menu } from 'antd';
import * as React from 'react';

import { TranslatedMessage } from '../../translations/data';
import Abus from './AbusModal';
import Reclamation from './ReclamationModal';

export const DropdownMenu = () => {
  const SideMenu = () => {
    return (
      <Menu>
        <Menu.Item key="1"></Menu.Item>
        <Menu.Item key="2">
          <Button disabled block>
            <TranslatedMessage id="addmedicalprocessQ" />
          </Button>
        </Menu.Item>
        <Menu.Item key="3">
          <Abus />
        </Menu.Item>
        <Menu.Item key="4">
          <Reclamation />
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <Dropdown key="more" overlay={SideMenu}>
        <Button style={{ color: '#d80000' }}>
          <TranslatedMessage id="reclamation" />
        </Button>
      </Dropdown>
    </>
  );
};
export default DropdownMenu;
