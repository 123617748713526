import * as t from 'io-ts';
import { DateFromISOString, withFallback } from 'io-ts-types';

export interface Vaccin {
  absent?: boolean;
  ageDayDoss?: number;
  ageMonthDoss?: number;
  ageYearDoss?: number;
  annule?: boolean;
  string?: string;
  campagneVaccination?: CampagneVaccination;
  centreVaccination?: CentreVaccination;
  codeBarre?: string;
  commentsDossVac?: string;
  confirm?: boolean;
  corpsVaccin?: CorpsVaccin;
  dateAcceuilDoss?: string;
  dateEnregistDoss?: string;
  dateModifDoss?: string;
  dateRdv?: string;
  dateRdvRappel?: string;
  dateVaccinationDoss?: string;
  desAbusEcart?: string;
  descAbusEcart2?: string;
  descEtatActuel?: string;
  descRdv?: string;
  etatDossier: EtatDossier;
  existQuestMedicale?: boolean;
  genetateCertificat?: boolean;
  gestionEtatDossierVaccins?: GestionEtatDossierVaccin[];
  id?: string;
  idDlgInscRdv?: string;
  inscription?: Inscription;
  isCircuitCovidDoss?: number;
  lotVaccin?: LotVaccin;
  nbRdv?: number;
  nbreMaladieDoss?: number;
  numGuichet?: number;
  ordreDossVacc?: number;
  urlCodeBarre?: string;
  userAcceuilDossierVac?: User;
  userAffectPriorite?: User;
  userAffectRDV?: User;
  userVaccDossierVacc?: User;
}

export interface CampagneVaccination {
  categorieCampagneByIdCategCampagne?: CategorieCampagneByIDCategCampagne;
  dateCreationCamp?: DateCreationCampClass;
  dateModifCamp?: DateCreationCampClass;
  dateVaccination?: string;
  delaisCampRappel?: number;
  descCampVacc?: string;
  etatCampagneByIdEtatCampagne?: EtatCampagneByIDEtatCampagne;
  fieldSuppl1?: string;
  fieldSuppl2?: string;
  fieldSuppl3?: string;
  idCampVacc?: number;
  libCampAr?: string;
  libCampFr?: string;
  moyNbrePersCv?: number;
  moyNbrePersDg?: number;
  nbrRestantRdv?: number;
  nbrTotalVaccins?: number;
  nbreCv?: number;
  nbreInscrites?: number;
  nbrePersAVacciner?: number;
  nbrePersVacciner?: number;
  nbreVacEstmDefct?: number;
  typeCvByIdTypeCv?: TypeCvtypeCv;
  vaccinsByNumVaccin?: VaccinsByNumVaccin;
}

export interface CategorieCampagneByIDCategCampagne {
  description?: string;
  id?: number;
  libCategorieCampagne?: string;
}

export interface DateCreationCampClass {
  date?: number;
  day?: number;
  hours?: number;
  minutes?: number;
  month?: number;
  nanos?: number;
  seconds?: number;
  time?: number;
  timezoneOffset?: number;
  year?: number;
}

export interface EtatCampagneByIDEtatCampagne {
  description?: string;
  id?: number;
  libEtatCompagne?: string;
}

export interface TypeCvtypeCv {
  description?: string;
  id?: number;
  libTypeCv?: string;
}

export interface VaccinsByNumVaccin {
  codeCouleurVacc?: string;
  dateCreationVac?: DateCreationCampClass;
  dateRecueVac?: string;
  delaisVaccRappel?: number;
  descVaccin?: string;
  fournisseurVaccByIdFourVacc?: FournisseurVaccByIDFourVacc;
  libCouleurVacc?: string;
  libVaccin?: string;

  nbreDoseFlacon?: number;
  noticeVaccin?: string;
  numVaccin?: number;
  refVaccin?: string;
  typeVaccinByIdTpVaccin?: TypeVaccinByIDTpVaccin;
}

export interface FournisseurVaccByIDFourVacc {
  desFourVacc?: string;
  id?: number;
  libFourVac?: string;

  pays?: Pays;
}

export interface Pays {
  areaPays?: number;
  iconPays?: string;
  idPays?: string;
  lattPays?: number;
  libPaysAR?: string;
  libPaysEN?: string;
  libPaysFR?: string;
  longPays?: number;
  obserPays?: string;
}

export interface TypeVaccinByIDTpVaccin {
  descTpVaccin?: string;
  id?: number;
  libTpVaccin?: string;

  nbreRappelVaccin?: number;
}

export interface CentreVaccination {
  adrCv?: string;

  areaCv?: number;
  capaciteAccueilCv?: number;
  capaciteMax?: number;
  dureeMoyOper?: number;
  etatCv?: number;
  gouvernorat?: Gouvernorat;
  iconCv?: string;
  idCv?: number;
  lattCv?: number;
  libCv?: string;
  longCv?: number;
  nbreInscritCv?: number;
  nbrePersoVaccineCv?: number;
  nbreVaccinateurs?: number;
  obserCv?: string;

  typeCv?: TypeCvtypeCv;
}

export interface Gouvernorat {
  areaGouv?: number;
  codeGouvCni?: number;
  iconGouv?: string;
  idGouv?: string;
  lattGouv?: number;
  libGouv?: string;

  longGouv?: number;
  obserGouv?: string;
}

export interface CorpsVaccin {
  descCorpsVacv?: string;
  idCorpsVaccin?: number;
  libCorpsVacc?: string;
}

export interface GestionEtatDossierVaccin {
  dateEnregistrementEtatDossier?: string;
  description?: string;
  userAffectEtat?: User;
}

export interface User {
  adressuserapp?: string;
  centreVaccinationByIdCv?: CentreVaccination;
  datecreationu?: string;
  datemodificationu?: string;
  delegationByIdDlg?: Delegation;
  descuserupp?: string;
  etatUtilisByIdEtatUtilis?: EtatUtilisByIDEtatUtilis;
  gouvernoratByIdGouv?: Gouvernorat;
  isfirstacces?: boolean;
  profiluserappByProfilid?: ProfiluserappByProfilid;
  sexeUtilis?: number;
  tel1UtilisPers?: string;
  tel2UtilisPers?: string;
  urlPhoto?: string;
  userappid?: number;
  userapplnom?: string;
  userapplogin?: string;
  userappprenom?: string;
  userapppwd?: string;
}

export interface Delegation {
  areaDlg?: number;
  codeDlgCni?: number;
  gouvernorat?: Gouvernorat;
  iconDlg?: string;
  idDlg?: string;
  lattDlg?: number;
  libDlg?: string;

  longDlg?: number;
  obserDlg?: string;
}

export interface EtatUtilisByIDEtatUtilis {
  descpEtatUtilis?: string;
  idEtatUtilis?: number;
  libEtatUtilis?: string;
}

export interface ProfiluserappByProfilid {
  id?: number;
  profilDesc?: string;
  profilName?: string;
}

export interface Inscription {
  adresseInscrit?: string;
  ageDayInsc?: number;
  ageMonthInsc?: number;
  ageYearInsc?: number;
  canalInscriptionByCodeCanal?: CanalInscriptionByCodeCanal;
  cinInscrit?: string;
  cinParrain?: string;
  codeInscription?: string;
  codePostalInscrit?: number;
  codeVerifTel?: number;
  dateFlagFct?: string;
  dateGenerateCoupon?: string;
  dateInscrit?: string;
  dateModif?: string;
  delegationByIdDlg?: Delegation;
  descInscrit?: string;
  dnInscrit?: string;
  emailInscrit?: string;
  etatInscrptionByIdEtatInscrit?: EtatInscrptionByIDEtatInscrit;
  etatVacc?: EtatVacc;
  idCampagneOld?: number;
  isCircuitCovidInsc?: number;
  langue?: string;
  municipaliteByIdMunic?: MunicipaliteByIDMunic;
  nbreMaladieInsc?: number;
  nomInscrit?: string;
  nomMereInscrit?: string;
  operTel?: string;
  pays?: Pays;
  prenomGrPereIns?: string;
  prenomInscrit?: string;
  prenomMereInscrit?: string;
  prenomPereInscrit?: string;
  rangInscrit?: number;
  scoreInscrit?: number;
  sexeByCodeSexe?: SexeByCodeSexe;
  telInscrit1?: string;
  telInscrit2?: string;
  typeDemande?: TypeDemande;
  typeFonctionByCodeTypeFct?: TypeFonctionByCodeTypeFct;
  typeParrainByIdTpParrain?: TypeParrainByIDTpParrain;
  userAppByUserappid?: User;
}

export interface CanalInscriptionByCodeCanal {
  codeCanal?: number;
  libCanal?: string;
}

export interface EtatInscrptionByIDEtatInscrit {
  desctEtatInscrit?: string;
  idEtatInscrit?: number;
  libEtatInscrit?: string;
}
export interface EtatVacc {
  description?: string;
  id?: number;
  libEtat?: string;
}

export interface MunicipaliteByIDMunic {
  areaMunic?: number;
  codeMunicCni?: number;
  iconMunic?: string;
  idMunic?: string;
  lattMunic?: number;
  libMunic?: string;

  longMunic?: number;
  obserMunic?: string;
}

export interface SexeByCodeSexe {
  codeSexe?: number;
  libSexe?: string;
}

export interface TypeDemande {
  id?: number;
  libelleTypeDmde?: string;
}

export interface TypeFonctionByCodeTypeFct {
  codeTypeFct?: number;
  libelleTypeFct?: string;
}

export interface TypeParrainByIDTpParrain {
  categorieParrainByIdCatgParr?: CategorieParrainByIDCatgParr;
  idTpParrain?: number;
  libTpParrain?: string;
  obserTpParrain?: string;
}

export interface CategorieParrainByIDCatgParr {
  descpCatgParr?: string;
  idCatgParr?: number;
  libCatgParr?: string;

  typeParrainsByIdCatgParr?: null[];
}

export interface LotVaccin {
  codeArticleLotVacc?: string;
  codeBarreLotVacc?: string;
  dateAffectLotVacc?: string;
  dateExpLotVacc?: string;
  descLotVacc?: string;
  idCodeBarreLotVacc?: number;
  nbreArticleParLotVacc?: number;
  numLotVacc?: number;
  userAffectLotVacc?: string;
  vaccinsByNumVaccin?: VaccinsByNumVaccin;
}

export const Center = t.type(
  {
    nom: withFallback(t.string, ''),
    address: withFallback(t.string, ''),
    type: withFallback(t.string, ''),
  },
  'Center'
);

export type Center = t.TypeOf<typeof Center>;

const emptyCenter: Center = { nom: '', address: '', type: '' };

export const AppVaccin = t.type(
  {
    nomVaccin: withFallback(t.string, ''),
    referenceVaccin: withFallback(t.string, ''),
    assetVaccin: withFallback(t.string, ''),
    vaccinatorNymId: withFallback(t.string, ''),
    numVaccin: withFallback(t.number, 0),
    date: withFallback(DateFromISOString, new Date(0)),
    fournisseur: withFallback(t.string, ''),
    vaccinateur: withFallback(t.string, ''),
    notice: withFallback(t.string, ''),
  },
  'AppVaccin'
);

export type AppVaccin = t.TypeOf<typeof AppVaccin>;

const emptyAppVaccin: AppVaccin = {
  nomVaccin: '',
  referenceVaccin: '',
  assetVaccin: '',
  vaccinatorNymId: '',
  numVaccin: 0,
  date: new Date(0),
  fournisseur: '',
  vaccinateur: '',
  notice: '',
};

export const Appointment = t.type(
  {
    date: withFallback(DateFromISOString, new Date(0)),
    center: withFallback(Center, emptyCenter),
    status: withFallback(
      t.keyof({ DONE: null, PENDING: null }, 'Status'),
      'PENDING'
    ),
    vaccin: withFallback(AppVaccin, emptyAppVaccin),
  },
  'Appointment'
);

export type Appointment = t.TypeOf<typeof Appointment>;

export const EtatDossier = t.type(
  {
    description: withFallback(t.string, ''),
    id: t.number,
    libelleEtatDossier: t.string,
  },
  'EtatDossier'
);
export type EtatDossier = t.TypeOf<typeof EtatDossier>;

export const Appointments = t.type(
  {
    count: t.number,
    appointments: t.array(Appointment),
    etatDossier: withFallback(t.union([EtatDossier, t.undefined]), undefined),
  },
  'Appointments'
);
export type Appointments = t.TypeOf<typeof Appointments>;

export const emptyAppointment: Appointments = {
  count: 0,
  etatDossier: undefined,
  appointments: [],
};
export type Nullable<T> = T | undefined | null;

const nullableString = t.union([t.null, t.string]);

export const HealthPass = t.partial({
  fullNameAR: nullableString,
  fullNameEN: nullableString,
  sponsoredFullNameAR: nullableString,
  sponsoredFullNameEN: nullableString,
  dateOfBirth: nullableString,
  effectiveDate: nullableString,
  qrData: nullableString,
  qrDataEu: nullableString,
});

export type HealthPass = t.TypeOf<typeof HealthPass>;
