import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import * as t from 'io-ts';
import { taskEither, either, task, io, option, record, array } from 'fp-ts';
import { flow, identity } from 'fp-ts/lib/function';
import { failure } from 'io-ts/lib/PathReporter';
import { pipe } from 'fp-ts/lib/pipeable';
export type { CancelToken } from 'axios';

type RequestConfig<A, B = unknown> = AxiosRequestConfig & {
  encoder?: t.Encoder<B, any>;
  decoder: t.Decoder<unknown, A>;
};

export const getCookie = (cname: string) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const setCookie = (cookie: string): io.IO<void> => () => {
  document.cookie = cookie;
};

const clearCookie = (cname: string): io.IO<void> =>
  setCookie(`${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`);

export const clearCookies = (): io.IO<void> =>
  pipe(['proof_of_ownership', 'access_token', 'session_token'], array.map(clearCookie), array.array.sequence(io.io));

const interceptAndSetCookie = (resp: AxiosResponse<any>): io.IO<AxiosResponse<any>> =>
  pipe(
    resp.headers,
    option.fromNullable,
    option.chain((h) =>
      pipe(
        h,
        t.record(t.string, t.any).decode,
        either.fold(() => option.none, option.some)
      )
    ),
    option.chain((x) => record.lookup('proof-of-ownership', x)),
    option.fold(
      () => () => resp,
      flow(
        setCookie,
        io.map(() => resp)
      )
    )
  );

axios.defaults.withCredentials = true;
axios.interceptors.response.use((r) => interceptAndSetCookie(r)());

axios.interceptors.request.use((req) => {
  const proofCookie = getCookie('proof_of_ownership');
  if (!!proofCookie) {
    req.headers = { ...req.headers, 'Proof-Of-Ownership': proofCookie };
  }
  req.headers = { ...req.headers, 'Json-Formatting': 'standard' };
  return req;
});

export const isAxiosError = (u: unknown): u is AxiosError =>
  u instanceof Object && u instanceof Error && 'isAxiosError' in u;

const AxiosErrorC = new t.Type<AxiosError, AxiosError, unknown>(
  'AxiosError',
  isAxiosError,
  (u, c) => (isAxiosError(u) ? t.success(u) : t.failure(u, c)),
  t.identity
);

export const validationErrToErr = (es: t.ValidationError[]) => new TypeError(failure(es).join('\n'));

const axiosErr = flow(AxiosErrorC.decode, either.fold(validationErrToErr, identity));

const decodeResponse = <A>(decoder: t.Decoder<unknown, A>) =>
  taskEither.chain((x: AxiosResponse<any>) =>
    pipe(task.of(decoder.decode(x.data)), taskEither.mapLeft(validationErrToErr))
  );

export const get = <A>(url: string, { decoder, ...config }: RequestConfig<A>): taskEither.TaskEither<Error, A> =>
  pipe(
    taskEither.tryCatch(() => axios.get(url, config), axiosErr),
    decodeResponse(decoder)
  );

export const post = <A, B>(
  url: string,
  body: B,
  { decoder, encoder, ...config }: RequestConfig<A, B>
): taskEither.TaskEither<Error, A> =>
  pipe(
    taskEither.tryCatch(() => axios.post(url, !!encoder ? encoder.encode(body) : body, config), axiosErr),
    decodeResponse(decoder)
  );

export const put = <A, B>(
  url: string,
  body: B,
  { decoder, encoder, ...config }: RequestConfig<A, B>
): taskEither.TaskEither<Error, A> =>
  pipe(
    taskEither.tryCatch(() => axios.put(url, !!encoder ? encoder.encode(body) : body, config), axiosErr),
    decodeResponse(decoder)
  );
export const delete_ = <A>(url: string, { decoder, ...config }: RequestConfig<A>): taskEither.TaskEither<Error, A> =>
  pipe(
    taskEither.tryCatch(() => axios.delete(url, config), axiosErr),
    decodeResponse(decoder)
  );
