import { Select } from 'antd';
import styled from 'styled-components';
import { TranslatedMessage } from '../../translations/data';

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  & > .ant-select {
    width: 80%;
  }
  padding: 16px;
`;

type Props = {
  value?: 1 | 2 | 3 | 4;
  onChange?: (_?: 1 | 2 | 3 | 4) => void;
};

const SelectDose = ({ value, onChange }: Props) => (
  <Centered>
    <TranslatedMessage id="selectDosePrompt" />
    <Select<1 | 2 | 3 | 4> value={value} onChange={onChange}>
      <Select.Option value={1}>
        <TranslatedMessage id="dose1" />
      </Select.Option>
      <Select.Option value={2}>
        <TranslatedMessage id="dose2" />
      </Select.Option>
      <Select.Option value={3}>
        <TranslatedMessage id="dose3" />
      </Select.Option>
      <Select.Option value={4}>
        <TranslatedMessage id="dose4" />
      </Select.Option>
    </Select>
  </Centered>
);

export default SelectDose;
