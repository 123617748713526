import { Layout, Row } from 'antd';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import styled from 'styled-components';

import Home from '../../components/Home/Home';
import Header from './Header';

const StyledContent = styled(Layout.Content)`
  padding: 24px;
  height: 100%;
  overflow: auto;
`;

const Sizer = styled.div`
  margin: auto;
  height: 100%;
`;

const AppLayout = () => (
  <Layout>
    <Header />
    <Layout className="layout">
      <StyledContent style={{ marginTop: 16 }}>
        <Row justify="center" style={{ marginBottom: 16 }}></Row>
        <Sizer>
          <Switch>
            <Route path="/" component={Home} />
          </Switch>
        </Sizer>
      </StyledContent>
    </Layout>
    <Layout.Footer>
      <Row justify="center">Evax ©2021 Created by Evax</Row>
    </Layout.Footer>
  </Layout>
);

export default AppLayout;
