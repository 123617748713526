//@ts-nocheck
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';

import {
  Col,
  ConfigProvider,
  Descriptions,
  Divider,
  Card,
  Image,
  Image as ImageAnt,
  Row,
  Typography,
  Space,
} from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { StaticSelectorWarper } from '../../shared/LocaleSelector';
import { Certif } from '../../shared/state/rapports';
import { State } from '../../shared/state/store';
import { useGrippeCertificateVerification } from '../Home/queries';
import logoar from './../../assets/logo-Arabe.png';
import logoen from './../../assets/logoMS2.png';
import logo from './../../assets/logoMS2.png';
import VerifyCertificateInfo, { CertificateHeader } from './VerifyCertificateInfo';
import { FullPageSpin } from '../Authentication/PrivateRoute';


const LandingLayout = styled.div`
width: 100%;
height: 100%;
display: grid;

grid-template-rows: auto;
`;

const LandingHeader = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
margin: 2rem 2rem auto 2rem;

img {
  height: 64px;
  margin: 0 auto 8px;
}
div {
  font-size: 20px;
}
`;

const LandingFooter = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-end;
margin-top: auto;
margin-bottom: auto;
`;

const LandingContent = styled.div`
margin: auto 2rem auto 2rem;
`;

const VerifyGrippeCertification = ({ locale }) => {

  const { ref = null } = useParams()
  const [certificate, setCertificate] = React.useState<Certif>();
  const {isLoading,query} = useGrippeCertificateVerification(locale === 'ar-TN' ? "AR" : locale.toUpperCase(), ref, {
    enabled: true,
    ref,
    onSuccess: (c) => setCertificate(c),
  });

    if (isLoading)
    return(<FullPageSpin tip={<FormattedMessage id="loading" />}/>)
    return (
      <LandingLayout style={{width:'calc(100vw - 20px'}}>
        <LandingContent>
          <Row justify="center">
            <Col lg={12} sm={24}>
              <Card>
                { certificate ? <VerifyCertificateInfo locale={locale} certificate={certificate} /> : <>
                  <Row justify="center" id="my-drawer">
                    <Col span={30}>
                        <Space align='center' size='middle' direction='vertical'>
                        <ExclamationCircleFilled style={{color:'#d80000',fontSize:'30px'}}/>
                          <Typography.Text strong>
                            <FormattedMessage id="isNotValidQrCode" />
                          </Typography.Text>
                          </Space>
                    </Col>
                  </Row>
                </> }
              </Card>
            </Col>
          </Row>
        </LandingContent>
        <LandingFooter>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={16}>
              <Row justify="center" gutter={[16, 16]}>
                <StaticSelectorWarper />
              </Row>
            </Col>
          </Row>
        </LandingFooter>
      </LandingLayout>
    );
}

const mapStateToProps = ({ locale }: State) => ({
  locale: locale,
});
export default connect(mapStateToProps)(VerifyGrippeCertification);




