import * as React from 'react';
import { FailableAction, Action, actionCreator } from '../types';
import { Actions as AllActions } from '../state/store';
import { FormattedMessage } from 'react-intl';

export type FailableActions = Extract<AllActions, FailableAction<any, any>>;
type FailableActionTypes = FailableActions['type'];
export type QueueItem = [FailableActionTypes, 'success' | 'error'];

export type State = QueueItem[];
export type CloseNotification = Action<'close_notification'>;
export const closeNotification = actionCreator<CloseNotification>(
  'close_notification'
);

export type Actions = CloseNotification;

type ActionsLookup = Record<
  FailableActionTypes,
  {
    success?: React.ReactNode;
    error?: React.ReactNode;
  }
>;

export const lookup: ActionsLookup = {
  'Language/UpdateLanguage': {},
  'Language/UpdateLanguage_result': {},
  'ACCOUNT/ChangeRdv_result': {
    error: <FormattedMessage id="Report/UpdateQuestion_result_ERROR" />,
    success: <FormattedMessage id="Report/UpdateQuestion_result_SUCCESS" />,
  },
  'Report/GetReponse_result': {},
  'Report/UpdateQuestion_result': {
    error: <FormattedMessage id="Report/UpdateQuestion_result_ERROR" />,
    success: <FormattedMessage id="Report/UpdateQuestion_result_SUCCESS" />,
  },
  'Report/UpdateQuestion': {},
  'Report/GetAbus_result': {},
  'Report/GetDeclaration_result': {},
  'Report/PostAbus': {},
  'Report/PostAbus_result': {},
  'Report/PostDeclaration': {},
  'Report/PostDeclaration_result': {
    error: <FormattedMessage id="Report/PostReclamation_result_ERROR" />,
    success: <FormattedMessage id="Report/PostReclamation_result_SUCCESS" />,
  },
  'Report/PostReclamation': {},
  'Report/PostReclamation_result': {
    error: <FormattedMessage id="Report/PostReclamation_result_ERROR" />,
    success: <FormattedMessage id="Report/PostReclamation_result_SUCCESS" />,
  },
  'Report/UpdateAbus': {},
  'Report/UpdateAbus_result': {},
  'Report/UpdateDeclaration': {},
  'Report/UpdateDeclaration_result': {},
  'Report/UpdateReclamation': {},
  'Report/UpdateReclamation_result': {},

  'ACCOUNT/GetVac_result': {},
  'ACCOUNT/CancelInscription_result': {
    error: <FormattedMessage id="ACCOUNT/CancelInscription_result_ERROR" />,
    success: <FormattedMessage id="ACCOUNT/CancelInscription_result_SUCCESS" />,
  },
  'ACCOUNT/confirmPhone_result': {
    error: <FormattedMessage id="ACCOUNT/confirmPhone_result_RESULT_ERROR" />,
    success: (
      <FormattedMessage id="ACCOUNT/confirmPhone_result_RESULT_SUCCESS" />
    ),
  },
  'ACCOUNT/preChangePhoneNumber_result': {
    error: <FormattedMessage id="ACCOUNT/preAuth_result_RESULT_ERROR" />,
    success: <FormattedMessage id="ACCOUNT/preAuth_result_RESULT_SUCCESS" />,
  },

  'ACCOUNT/GetSexe_result': {},

  'ACCOUNT/UpdateAccount': {},
  'ACCOUNT/UpdateAccount_result': {
    error: <FormattedMessage id="ACCOUNT/update_result_RESULT_ERROR" />,
    success: <FormattedMessage id="ACCOUNT/update_result_RESULT_SUCCESS" />,
  },
  'ACCOUNT/GetAccount_result': {},

  'ACCOUNT/login_result': {
    error: <FormattedMessage id="ACCOUNT/login_result_RESULT_ERROR" />,
    success: <FormattedMessage id="ACCOUNT/login_result_RESULT_SUCCESS" />,
  },
  'ACCOUNT/preAuth_result': {
    error: <FormattedMessage id="ACCOUNT/preAuth_result_RESULT_ERROR" />,
    success: <FormattedMessage id="ACCOUNT/preAuth_result_RESULT_SUCCESS" />,
  },
  'ACCOUNT/logout_result': {},
};
